@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.ViewScreeningRequestsHeader {
  @include full-width();
  padding-top: $header-height + 40px;
  padding-bottom: 60px;
  border-bottom: 1px solid $border-color;
  margin-bottom: 60px;

  h1 {
    @include font-size(50)
  }
}
.screening-request {
  @include full-width();
  padding: 40px 30px;
  border-radius: 16px;
  @include box-shadow();
  margin-bottom: 40px;
}
.screening-request__feature {
  margin-bottom: 30px;
  color: $black;
  @include font-heading();
  @include weight-bold();
  @include font-size(20);
  position: relative;
  line-height: 1;
  @include flex-row(flex-start, flex-start);
  @include space-x(10px);
}