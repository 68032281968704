.help-tip {
  cursor: pointer;
  background: transparent;
  border: none;
  @include appearance();
  padding: 0px;

  &:before {
    content: "?";
    display: inline-block;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    text-align: center;
    line-height: 30px;
    @include font-size(24);
    background: $secondary-color;
    color: $black;
    @include font-heading();
  }
}

.modal {
  width: 100vw;
  height: auto;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;

  .modal__overlay {
    @include full-height();
    background: $black;
    opacity: 0.8;
    position: fixed;
    top: 0;
    left: 0;
  }

  .modal__wrapper {
    width: 95%;
    max-width: 750px;
    margin: 40px auto;
    position: relative;
  }

  .modal__container {
    width: 100%;
    background: $white;
    height: auto;
    min-height: 450px;
    padding: 50px;
    @include flex-column();
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    position: relative;
    border-radius: 10px;

    h1 {
      color: $primary-color;
    }
  }
}

// Side modal
.side-modal {
  @include full-page();
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: 17px;
  display: block;

  .side-modal__overlay {
    @include full-height();
    background: $black;
    opacity: 0.8;
    position: fixed;
    top: 0;
    left: 0;
  }

  .side-modal__wrapper {
    width: 25vw;
    min-width: 500px;
    margin: 0px auto 0px 0px;
    position: relative;
  }

  .side-modal__container {
    width: 100%;
    background: $white;
    height: auto;
    min-height: 100vh;
    padding: 25px 45px;
    @include flex-column(flex-start, flex-start);
    text-align: left;
    margin-top: 0px;
    margin-bottom: 0px;
    position: relative;
    border-radius: 0px;

    h1 {
      color: $primary-color;
    }
  }
}
