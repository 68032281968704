@import "../../../assets/sass/app.scss";
.ResubmitReferenceModal {
  .row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 5%;
    h3 {
      width: 75%;
    }
  }
  .background {
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .modalContainer {
      position: absolute;
      width: 750px;
      height: 450px;
      left: 345.35px;
      top: 160.93px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid $black;
      background-color: $white;
      z-index: 1000;

      h1 {
        font-size: 30px;
        margin: 0;
      }

      .textContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        margin: 0;

        h4 {
          text-align: center;
          margin: 0;
        }
      }
    }
  }
}
