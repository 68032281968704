@import "../../assets/sass/app.scss";

.ProgressBar {
  @include full-width();
  padding-bottom: 80px;

  .ProgressBar__container {
    @include flex-row(flex-start, flex-start);


  }

  .ProgressBar__item {
    position: relative;
    width: 150px;
    height: auto;
    @include flex-column(flex-start, center);

    &:not(:first-of-type):before {
      content: '';
      width: 100%;
      height: 23px;
      background: $secondary-color;
      position: absolute;
      top: 9px;
      right: 50%;
      z-index: 1;
    }

    .ProgressBar__item__count {
      height: 40px;
      width: 40px;
      line-height: 38px;
      text-align: center;
      @include font-size(20);
      @include weight-bold();
      color: $black;
      border-radius: 40px;
      background: $secondary-color;
      position: relative;
      z-index: 2;
      box-shadow: 0px 7px 30px 0px rgba(0, 0, 0, 0.1);

      @include md(){
        @include font-size(18);
      }

      @include xs(){
        @include font-size(16);
      }
    }

    .ProgressBar__item__label {
      @include font-size(15);
      @include weight-normal();
      line-height: 1.3;
      letter-spacing: -1.5%;
      text-align: center;
      margin-top: 10px;
      padding: 0px 10px;

      @include md(){
        display: none;
      }
    }

    &.ProgressBar__item--completed {
      &:before {
        background: $primary-color;
      }

      .ProgressBar__item__count {
        color: $white;
        background: $primary-color;
      }
    }

    &.ProgressBar__item--current {
      &:before {
        background: $primary-color;
      }

      .ProgressBar__item__count {
        color: $primary-color;
        background: $white;
        border: 2px solid $primary-color;
        line-height: 36px;
      }
    }

  }
}
