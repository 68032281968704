@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.CoapplicantSendLink {
  @include full-box();
  padding: 50px 50px;
  .CoapplicantSendLink__item-container {
    margin-bottom: 80px;

    .CoapplicantSendLink__item {
      border: 1px solid $border-color;
      border-radius: 16px;
      margin-right: 0px !important;
      margin-left: 0px !important;
      padding: 40px 20px 40px 20px;
      margin-bottom: 40px;

      label {
        @include font-size(25);
        @include weight-bold();
        font-family: $font-heading;
      }
    }
  }
}
.coapplicantToggle {
  .choose-input-group {
    width: auto;
    min-width: 80px;
    height: 35px;
    line-height: 14px;
    border-radius: 40px;
    padding: 0px 20px;
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 700;
    font-family: "Montserrat Alternates", sans-serif;
    color: #000105;
    border: 2px solid #e5e5e5;
    background: #e5e5e5;
    transition: all 200ms ease-in-out;
    text-align: center;
    cursor: pointer;
    margin-bottom: 0px;

    &:hover {
      background-color: $primary-color-light;
      border-color: $primary-color-light;
    }
    &.active {
      background-color: $primary-color-light;
      border-color: $primary-color-light;
    }
  }
}
