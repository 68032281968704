@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.BasicLLRequest {
  @include full-box();
padding: 50px 50px;

.order-summary {
  position: sticky;
  top: 55px;
}

  .checkBtn {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 0px;
    margin: 20px;
  }

  .greyBtn {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 0px;
    margin: 20px;
  }

  .advancedFeatures {
    justify-content: flex-end;
    display: flex;
    flex-direction:row-reverse;
    vertical-align: middle;
    margin-right: 0;
    float: left;
    padding: 0px;
  }

  .advancedFtText{
    font-family: $font-heading;
    display: block;
    font-size: 1.25rem;
    padding-top: 15px;
  }

  .btn-group {
    margin-top: 40px;
  }
}