@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.editLLComponent {
  border: 1px solid $border-color;
  border-radius: 16px;
  width: 75%;
  padding: 5%;
  margin-bottom: 5%;
  position: relative;

  .btn--edit {
    position: absolute;
    top: -10px;
    right: -10px;
  }
}
