@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.ChoosePath {
  @include full-box();
  padding: 50px 50px;
  span {
    margin-top: 5%;
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 27px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
  }

  .disabled {
    opacity: 0.2;
  }
  .comingSoon {
    position: absolute;
    top: 55px;
    right: 15%;
    background-color: $primary-color;
    border-radius: 16px;
    padding: 5px 50px;
    color: white; 
    transform: rotate(-10deg);
    .comingText {
      color: white; 
      padding-top: 10px;
      padding-bottom: 0px;

    }
  }
  .ChoosePath__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    position: relative;
  }

  .btn--back {
    margin-bottom: 65px;
  }

  .ChoosePath__btn-container {
    margin-bottom: 80px;
    padding-top: 40px;

    @include md() {
      @include space-y(20px);

      h2 {
        text-align: center;
      }
    }
  }

  .form-checkbox__label {
    font-family: $font-heading;
    padding-bottom: 10px;
    display: block;
  }

  .button-text {
    vertical-align: middle;
  }

  .ChoosePath__btn {
    width: 100%;
    height: 200px;
    border: 2px solid $border-color;
    border-radius: 16px;
    @include font-text();
    @include font-size(16);
    @include weight-thin();
    color: $black;
    line-height: 1.3;
    padding: 60px 20px;
    flex-direction: column;

    display: block;
    @include font-size(18);
    @include font-heading();
    margin-bottom: 20px;

    @include md() {
      @include font-size(18);
    }

    @include xs() {
      @include font-size(16);
    }

    &:hover {
      border-color: $primary-color-light;
      @include box-shadow();
    }

    &.ChoosePath__btn--active {
      border-color: $primary-color;
    }
  }
}
