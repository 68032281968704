@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";
.TermsConditions {
  @include full-box();

  ol, ul {
    list-style-type: unset;
    list-style-position: outside;
    margin-left: 40px;
    margin-bottom: 10px;
    li {
      @include weight-normal();
      @include font-text();
      margin-bottom: 20px;
      line-height: 1.5;
    }
  }
}