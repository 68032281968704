.nowrap {
  white-space: nowrap;
}

.title-container {
  @include flex-row(flex-start);
  margin-bottom: 40px;
  margin-top: 10px;

  * {
    margin-top: 0;
    margin-bottom: 0px;
  }

  > * + * {
    margin-left: 20px;
  }
}

.text-center {
  text-align: center !important
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important
}

.w-full {
  width: 100%;
}

// Margin and Padding helpers
.mb-auto, .my-auto {
  margin-bottom: auto !important;
}

.mb-0, .my-0 {
  margin-bottom: 0px !important;
}

.mb-0-5, .my-0-5 {
  margin-bottom: 10px !important;
}

.mb-1, .my-1 {
  margin-bottom: 20px !important;
}

.mb-2, .my-2 {
  //margin-bottom: 40px !important; Previous value left incase need to revert
  margin-bottom: 20px !important;
}

.mb-3, .my-3 {
  //margin-bottom: 50px !important; Previous value left incase need to revert
  margin-bottom: 25px !important;
}

.mb-4, .my-4 {
  margin-bottom: 80px !important;
}

.mt-auto, .my-auto {
  margin-top: auto !important;
}

.mt-0, .my-0 {
  margin-top: 0px !important;
}

.mt-0-5, .my-0-5 {
  margin-top: 10px !important;
}

.mt-1, .my-1 {
  margin-top: 20px !important;
}

.mt-2, .my-2 {
  margin-top: 40px !important;
}

.mt-3, .my-3 {
  margin-top: 60px !important;
}

.mt-4, .my-4 {
  margin-top: 80px !important;
}

.ml-auto, .mx-auto {
  margin-left: auto !important;
}

.ml-0, .mx-0 {
  margin-left: 0px !important;
}

.ml-0-5, .mx-0-5 {
  margin-left: 10px !important;
}

.ml-1, .mx-1 {
  margin-left: 20px !important;
}

.ml-2, .mx-2 {
  margin-left: 40px !important;
}

.ml-3, .mx-3 {
  margin-left: 60px !important;
}

.ml-4, .mx-4 {
  margin-left: 80px !important;
}

.mr-auto, .mx-auto {
  margin-right: auto !important;
}

.mr-0, .mx-0 {
  margin-right: 0px !important;
}

.mr-0-5, .mx-0-5 {
  margin-right: 10px !important;
}

.mr-1, .mx-1 {
  margin-right: 20px !important;
}

.mr-2, .mx-2 {
  margin-right: 40px !important;
}

.mr-3, .mx-3 {
  margin-right: 60px !important;
}

.mr-4, .mx-4 {
  margin-right: 80px !important;
}

.pb-0, .py-0 {
  padding-bottom: 0px !important;
}

.pb-0-5, .py-0-5 {
  padding-bottom: 10px !important;
}

.pb-1, .py-1 {
  padding-bottom: 20px !important;
}

.pb-2, .py-2 {
  padding-bottom: 40px !important;
}

.pb-3, .py-3 {
  padding-bottom: 60px !important;
}

.pb-4, .py-4 {
  padding-bottom: 80px !important;
}

.pt-0, .py-0 {
  padding-top: 0px !important;
}

.pt-0-5, .py-0-5 {
  padding-top: 10px !important;
}

.pt-1, .py-1 {
  padding-top: 20px !important;
}

.pt-2, .py-2 {
  padding-top: 40px !important;
}

.pt-3, .py-3 {
  padding-top: 60px !important;
}

.pt-4, .py-4 {
  padding-top: 80px !important;
}

.pl-0, .px-0 {
  padding-left: 0px !important;
}

.pl-0-5, .px-0-5 {
  padding-left: 10px !important;
}

.pl-1, .px-1 {
  padding-left: 20px !important;
}

.pl-2, .px-2 {
  padding-left: 40px !important;
}

.pl-3, .px-3 {
  padding-left: 60px !important;
}

.pl-4, .px-4 {
  padding-left: 80px !important;
}

.pr-0, .px-0 {
  padding-right: 0px !important;
}

.pr-0-5, .px-0-5 {
  padding-right: 10px !important;
}

.pr-1, .px-1 {
  padding-right: 20px !important;
}

.pr-2, .px-2 {
  padding-right: 40px !important;
}

.pr-3, .px-3 {
  padding-right: 60px !important;
}

.pr-4, .px-4 {
  padding-right: 80px !important;
}

.desktop-only {
  @include md(){
    display: none;
  }
}