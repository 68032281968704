@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.ApplicationHeader {
  @include full-width();
  padding-top: $header-height + 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid $border-color;
  margin-bottom: 45px;
  .container {
    height: auto;
    .title-container {
      .form-group {
        height: 4em;
        .updateAddress {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 16px;
        }
        .autocomplete-dropdown-container {
          background: white;
          z-index: 999;
        }
      }
    }
  }
  .address-field-google {
    line-height: 1;
    font-family: "Montserrat Alternates", sans-serif;
    font-weight: 700;
    color: #000105;
    width: 100%;
    margin: 1%;
  }

  h1 {
    @include font-size(50);
    text-transform: lowercase;
  }

  .address-field-google {
    @include font-size(50);
    -webkit-appearance: none;
    outline: none;
    border: none;
    text-transform: lowercase;
    input {
      outline: none;
    }
    height: auto;
  }
}
.top {
  text-align: center;
  padding-top: 20px;
  padding-left: 8px;
}

.btn-90 {
  transform: rotate(90deg);
  padding: 10px;
}
.btn--back {
  margin-bottom: 40px;
}

.applicationsBtn {
  margin-bottom: 40px;
  margin-top: 10px;
}

.resetFilterBtn {
  text-decoration: underline;
  text-underline: #dc3545;
}

.resetFilterBtn:hover {
  cursor: pointer;
}

.Application__search-filters {
  flex-wrap: nowrap !important;

  @media (max-width: 1300px) {
    flex-wrap: wrap !important;
  }

  .searchApplicantInput {
    display: flex;
    align-items: center;
    padding: 2px 1rem;
    input[type="text"] {
      height: 48px;
      border-radius: 6px;
    }
  }
}

.Application__filters {
  @include flex-row(flex-end, flex-start);
  display: flex;
  flex-wrap: nowrap;

  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px) {
    flex-wrap: wrap !important;
    justify-content: flex-start;

    > .btn--filter {
      margin-bottom: 10px;
      margin-left: 10px;
    }

    .btn--filter {
      max-height: 2rem;
    }

    .btn--moreFilters {
      max-height: 2rem;
    }
  }

  > * + * {
    margin-bottom: 10px;
    margin-left: 10px;
  }
}

// Applicant Card
.applicant-card {
  @include full-width();
  @include box-shadow();
  border-radius: 16px;
  color: $black;


  &:not(:last-of-type) {
    margin-bottom: 40px;
  }

  &.applicant-card--is-selected {
    border: 2px solid $accent-color;
    color: $black;
  }

  .favouriteContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .attribute-icon--favorite {
      width: 30px;
      height: 30px;
      display: block;
      margin-right: 8px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      flex-shrink: 0;
    }

    .fav {
      background-image: url("../../../assets/svg/starGreen.svg");
    }

    .notFav {
      background-image: url("../../../assets/svg/star.svg");
    }
  }

  h2 {
    margin-top: 0;
  }

  .applicant-card__container {
    @include full-width();
    padding: 30px;
    border: 2px solid $white;
    border-radius: 16px;
  }

  .applicant-card__attributes-container {
    @include flex-row(space-between);
    flex-wrap: wrap;
    position: relative;
    margin-bottom: 20px;
  }

  a:hover .applicant-card__container {
    border: 2px solid $accent-color;
    border-radius: 16px;
    color: $black;
  }

  a:focus .applicant-card__container {
    border: 2px solid $accent-color;
    border-radius: 16px;
    color: $black;
  }

  .init-focus {
    border: 2px solid $accent-color ;
    border-radius: 16px;
    color: $black;
  }

  .applicant-card__completion-container {
    @include font-size(15);
    @include font-heading();
    color: $black;
  }

  &.applicant-card--retracted {
    h2,
    .applicant-card__attributes-container {
      opacity: 50%;
    }
  }

  .applicant-card__retracted {
    @include flex-row(flex-start);
    @include space-x(20px);
    @include font-heading();
  }
}

.applicant__attribute {
  @include flex-row(flex-start);
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 20px;

  .applicant__attribute__label {
    @include font-heading();
    @include weight-bold();
    @include font-size(14);
  }

  .applicant__attribute__icon {
    width: 30px;
    height: 30px;
    display: block;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    flex-shrink: 0;
  }

  .attribute-icon--household {
    background-image: url("../../../assets/svg/house.svg");
    color: $primary-color;
    text-align: center;
    line-height: 30px;
    padding-top: 3px;
    @include font-size(13);
    @include weight-bold();
  }

  .attribute-icon--income {
    background-image: url("../../../assets/svg/income.svg");
  }

  .attribute-icon--has-pets {
    background-image: url("../../../assets/svg/pets.svg");
  }


  .attribute-icon--non-smoker {
    background-image: url("../../../assets/svg/non-smoking.svg");
  }

  .attribute-icon--checked {
    background: $primary-color;
    border-radius: 30px;
    height: 20px;
    width: 20px;
    margin-left: 5px;
    margin-right: 12px;
    background-size: 12px 12px;
    background-image: url("../../../assets/svg/checkmark.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }

  .attribute-icon--check-disabled {
    background: $secondary-color-light;
    height: 20px;
    width: 20px;
    margin-left: 5px;
    margin-right: 12px;
    background-size: 12px 12px;
    border-radius: 30px;
    background-image: url("../../../assets/svg/checkmark.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
}

// Applicant Info ---------------------------------------------------------
.applicant-info-container {
  @include full-width();
  border-radius: 16px;
  @include box-shadow();
  padding: 20px;
  > section + section {
    margin-top: 40px;
  }
}
