@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.TenantLanding {
  @include full-width();
  @include splash-padding();
  @media (max-width: 576px) {
   padding-left: 20px;
  }
h4 {
  font-size: 1.25rem;
  @media (max-width: 576px) {
    font-size: 1rem;
  }
}

  h1 {
    font-weight: 700;
    font-size: 50px;
    line-height: 61px;
    @media (max-width: 767px) {
      font-size: 44px;
      line-height: 54px;
    }
    @media (max-width: 576px) {
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 20px;
    }
  }

  .link {
    border-bottom: 2px solid;
  }



  .TenantLanding__details-container {
    @include full-width();
    // background: $secondary-color;
    padding: 0px 50px;
    h2 {
      color: $primary-color;
    }
    .infoBox {
      border: 1px solid #e0e0e0;
      border-radius: 16px;
      padding: 20px;
      box-shadow: 0px 5px 22px rgba(0, 1, 5, 0.1);
    }
  }

  .TenantLanding__details {
    margin-top: 50px;
    @include flex-row(space-between);
    flex-wrap: wrap;
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  .TenantLanding__details-item {
    width: calc(50% - 40px);
    height: auto;
    @include flex-row(flex-start, flex-start);
    margin-bottom: 30px;

    @media (max-width: 767px) {
      width: calc(100% - 40px);
    }

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      object-position: center;
      margin-right: 20px;
    }
  }
  .row {
    display: flex;
    .btn {
      margin-right: 40px;
      margin-bottom: 20px;
      margin-top: 10px;
    }
    .btn--secondary {
      background: none;
      color: $black;
      text-decoration: underline;

      &:hover {
        color: rgb(67, 59, 59);
      }
    }
  }
}
