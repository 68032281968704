@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.LandlordDashboard {
  @include full-box();
  border: none;

  h1 {
    @include font-size(50);
    margin-bottom: 80px;
  }

  .LandlordDashboard__search-row {
    @include xs(){
      text-align: center;
      .form-search {
        margin-bottom: 20px;
      }
    }
  }

  .LandlordDashboard__properties {
    @include full-width();
    padding-top: 40px;

    table {
      border-collapse: collapse;
      width: 100%;
      max-width: 100%;
      background-color: transparent;

      th {
        @include weight-bold();
        @include font-heading();
        @include font-size(20);
        text-align: center;
        padding: 10px 30px 20px 30px;

        @include md(){
          @include font-size(16);
          padding: 5px 20px 10px 20px;
        }

        @include sm(){
          @include font-size(14);
          padding: 3px 5px 5px 5px;
        }
      }

      tbody {

        tr {
          background: rgba($secondary-color, 0.2);
          border-bottom: 4px solid $white;

          td {
            padding: 15px 30px;
            line-height: 150%; 
            @include font-text();
            @include weight-thin();
            @include font-size(16);
            color: $black;
            border: none;
            text-align: center;

            @include md(){
              @include font-size(16);
              padding: 15px 10px;
            }

            @include sm(){
              @include font-size(14);
              padding: 15px 5px;

              .btn--secondary-alt {
                @include font-size(12);
                padding: 0 10px;
                line-height: 22px;
                height: 25px;
              }
            }
          }

          .btn--secondary-alt {
            min-width: auto;
          }
        }
      }
    }
  }
}