label {
  @include font-size(20);
  font-family: $font-normal;
  color: $black;
  line-height: 1;
  font-weight: $weight-thin;
  margin-bottom: 10px;
  display: inline-block;
}

.form-group {
  @include full-width();
  //margin-bottom: 40px; -- Previous value left incase need to revert
  margin-bottom: 25px;

  &.form-group--inline {
    @include flex-row(flex-start);
    width: auto;
    height: auto;
    margin-bottom: 0px;

    label {
      margin-right: 10px;
      margin-bottom: 0px;
      @include font-size(16);
    }
  }

  .form-group__form-control,
  .location-search-input {
    display: block;
    height: 35px;
    width: 100%;
    line-height: 35px;
    padding: 0 20px;
    color: $black;
    border: 1px solid $border-color;
    border-radius: 35px;
    @include transition();

    &:focus {
      @include box-shadow();
    }
  }

  textarea {
    min-height: 200px;
    max-height: 1000px;
    border-radius: 16px !important;
  }
}

// File upload
.form-group__file-upload-container {
  min-height: 200px;
  width: 100%;

  .filepond--root,
  .filepond--root .filepond--drop-label {
    min-height: 200px;
  }

  .filepond--panel {
    border-radius: 16px;
    overflow: hidden;
  }

  .filepond--panel-root {
    background: #f0f0f1;
  }

  .filepond--credits {
    display: none;
  }

  .filepond--label-action {
    padding: 0 15px;
    margin-left: 5px;
    border-radius: 30px;
    line-height: 30px;
    height: 30px;
    display: inline-block;
    background: $secondary-color;
    @include transition();
    text-decoration: none;

    &:hover {
      background: $primary-color-light;
    }
  }
}

// Append text input
.form-group_appended {
  @include flex-row();
  justify-content: flex-start !important;

  .form-group__form-control {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
  }

  .btn--primary {
    min-width: unset;
    height: 35px;
    line-height: 33px;
    border: 1px solid $secondary-color;
    padding: 0 30px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

// Search
.form-search {
  // TEMP HIDING DISPLAY
  display: none;
  // TEMP HIDING DISPLAY
  height: 48px;
  width: 100%;
  line-height: 48px;
  border-radius: 48px;
  border: 1px solid $border-color;
  position: relative;
  padding: 0px 20px 0px 60px;
  background-image: url(../../svg/search.svg);
  background-size: 20px 20px;
  background-position: 20px 14px;
  background-repeat: no-repeat;
}

.form-checkbox {
  @include flex-row("flex-start", "flex-start");

  &.form-checkbox--mega-checkbox {
    border: 1px solid $border-color;
    border-radius: 16px;
    padding: 30px 20px;
    @include flex-row(flex-start, center);
    position: relative;
    cursor: pointer;
    @include transition();
    &:hover {
      border-color: $primary-color-light;
      @include box-shadow(2px, 10px, 0.08);
    }
    .form-checkbox__form-control + span:before {
      content: "";
      position: absolute;
      top: 0px;
      left: 0px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      z-index: -1;
      @include transition();
      border-radius: 14px;
      border: 2px solid transparent;
    }

    .form-checkbox__form-control:checked + span:before {
      background-color: $white !important;
      border-color: $primary-color;

      &:hover {
        background-color: $primary-color-light;
        border-color: $primary-color;
      }
    }
  }

  .form-checkbox__form-control {
    height: 18px;
    accent-color: $primary-color;
    color: $white;
    width: 18px;
    margin-right: 20px;
    flex-shrink: 0;
    border: none;
    border-radius: 10px;
    background: $secondary-color;
  
  }
  .form-checkbox__label {
    @include font-size(18);
    font-weight: $weight-bold;
  }
}

.form-checkbox__details {
  display: block;
  line-height: 1.3;
  @include font-text();
  @include weight-normal();
  @include font-size(16);
}

.code-form-group {
  border: none;
  margin-bottom: 40px;

  input {
    height: 60px;
    width: 60px;
    line-height: 1;
    padding: 5px;
    @include font-size(50);
    @include weight-bold();
    color: $black;
    text-align: center;
    border: none;
    background: $secondary-color;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
}

.form-choose-group {
  @include flex-row(flex-start);
  //flex-wrap: wrap;
  @include space-x(10px);
}
.choose-input-group {
  .choose-input--hide {
    display: none;
  }

  label {
    width: auto;
    min-width: 80px;
    height: auto;
    line-height: 14px;
    border-radius: 40px;
    padding: 13px 20px;
    @include font-size(12);
    @include weight-bold();
    font-family: $font-heading;
    color: $black;
    border: 2px solid $secondary-color-light;
    background: $secondary-color-light;
    @include transition();
    text-align: center;
    cursor: pointer;
    margin-bottom: 0px;

    @include sm() {
      padding: 7px 10px;
    }

    &:hover {
      background-color: $primary-color-light;
      border-color: $primary-color-light;
    }
  }

  .choose-input:checked + label {
    background-color: $primary-color-light !important;
    border-color: $primary-color;

    &:hover {
      background-color: $primary-color-light;
      border-color: $primary-color;
    }
  }
}

/* The slider itself */
input[type="range"] {
  @include appearance();
  width: 100%; /* Full-width */
  height: 15px; /* Specified height */
  background: $secondary-color-light; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  @include transition();
  border-radius: 8px;
}

/* Mouse-over effects */
input[type="range"]:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
input[type="range"]::-webkit-slider-thumb {
  @include appearance();
  width: 35px; /* Set a specific slider handle width */
  height: 35px; /* Slider handle height */
  background: $primary-color; /* Green background */
  cursor: pointer; /* Cursor on hover */
  border-radius: 100%;
}

input[type="range"]::-moz-range-thumb {
  width: 35px; /* Set a specific slider handle width */
  height: 35px; /* Slider handle height */
  border-radius: 100%;
  background: $primary-color; /* Green background */
  cursor: pointer; /* Cursor on hover */
}

/* Number Picker */
.form-group__number-container {
  @include flex-row(flex-start);

  .btn {
    @include font-size(40);
    @include font-heading();
    height: 38px;
    width: 50px;
    line-height: 38px;
    border: 1px solid $border-color;
    text-align: center;
    letter-spacing: unset;
    padding: 0px;
    background: rgba($secondary-color, 0.1);

    &:hover {
      background: rgba($secondary-color, 0.3);
    }

    &:first-child {
      border-top-left-radius: 38px;
      border-bottom-left-radius: 38px;

      &:before {
        content: "\2212";
      }
    }
    &:last-child {
      border-top-right-radius: 38px;
      border-bottom-right-radius: 38px;
      &:before {
        content: "\002B";
      }
    }
  }

  .form-group__form-number {
    width: auto;
    min-width: 60px;
    max-width: 100px;
    height: 38px;
    line-height: 38px;
    @include font-size(25);
    @include font-heading();
    border: 1px solid $border-color;
    border-right: none;
    border-left: none;
    @include appearance();
    text-align: center;
  }
}

/* Inputted Item */

.form-group-inline {
  flex-grow: 1;
  max-width: 300px;
}
.inline-input-group {
  @include flex-row(flex-start);
  flex-wrap: wrap;
  > * {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
.inline-inputted-item {
  display: inline-block;
  border-radius: 38px;
  border: 1px solid $border-color;
  padding: 0px 20px;
  min-height: 38px;
  @include flex-row();
  @include space-x(10px);
  line-height: 15px;
  @include font-size(15);

  button {
    @include font-size(14);
    line-height: 15px;
    padding: 0px;
  }
}

.inline-input {
  border-radius: 38px;
  border: 1px solid $border-color;
  padding: 0px 20px;
  min-height: 38px;
  flex-grow: 1;
  width: 100%;
}
.input-error-border-top {
  border-top: 3px solid $danger-color !important;
  box-sizing: border-box;
}
.input-error-border-left {
  border-left: 3px solid $danger-color !important;
  box-sizing: border-box;
}
.input-error-border-right {
  border-right: 3px solid $danger-color !important;
  box-sizing: border-box;
}
.input-error-border-bottom {
  border-bottom: 3px solid $danger-color !important;
  box-sizing: border-box;
}

.input-error-border {
  border: 3px solid $danger-color !important;
  box-sizing: border-box;
}

.input-maybe-error {
  transition: border 200ms ease-in-out;
}
