@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.ApplyCoApplicants {
  .btn {
    position: relative;
    .disabled {
      opacity: 0.2;
    }
    .comingSoon {
      position: absolute;
      top: 25px;
      right: 20%;
      background-color: $primary-color;
      border-radius: 16px;
      padding: 5px 50px;
      color: white;
      transform: rotate(-10deg);
      .comingText {
        color: white;
        padding-top: 10px;
        padding-bottom: 0px;
      }
    }
  }

  @include full-box();
  padding: 50px 50px;
  .ApplyCoApplicants__choices {
    @include flex-row(flex-start);
    margin: 80px 0px;

    @include sm() {
      margin: 40px 0px 80px;
    }

    @include sm() {
      flex-direction: column;
    }
    .co-app-title {
      margin-bottom: 0px;
    }

    .btn {
      height: 100%;
      width: 50%;
      padding: 50px 20px;
      @include font-size(30);
      line-height: 27px;
      background: rgba($secondary-color, 0.1);
      border: 2px solid $border-color;
      border-radius: 16px;

      @include sm() {
        width: 100%;
      }

      &:hover,
      &:focus {
        background: $white;
        border-color: $primary-color;

        @include box-shadow();
      }

      &:not(:last-of-type) {
        margin-right: 10px;

        @include sm() {
          margin-bottom: 10px;
          margin-right: 0px;
        }
      }
    }
  }
}
