/***********************************************************************/
/*==== SITE COLORS ====================================================*/
/***********************************************************************/
/* Primary Color */
$primary-color: #8cc947;
$primary-color-light: #d1e9b5;

/* Secondary Color */
$secondary-color: #e0e0e0;
$secondary-color-light: #e5e5e5;

/* Accent Color */
$accent-color: #66c2d7;
$orange: #ff8039;

/* Text Color */
$text-color-primary: #263238;
$text-color-secondary: #cfd8dc;
$text-color-light: #eceff1; // Fonts on a dark background
$text-color-dark: #263238; // Fonts on a light background
/* Body Color (default text color) */
$body-color: #000105;

/* Background Color (For page. Typically white or grey) */
$background-color: #fafafa;
$background-color-light: #ffffff;
$background-color-dark: #e0e0e0;

/* Overlay Color (typically used for image overlays) */
$overlay-color: fade(#37474f, 40%);

/* Input Place Holder Color */
$input-placeholder-color: #bbbbbb;

/* Border color (default) */
$border-color: #e0e0e0;

/*********************************************************************/
/*==== DEFAULT COLORS ================================================*/
/***********************************************************************/
/* Shades */
$white: #ffffff;
$grey: #808080;
$black: #000105;
$red-light: #ed6c63;
$red-dark: #bb2011;
$yellow: #ffea85;
$danger-color: #c54a03;
$success-color: #28a745;
$in-progress-color: darken(#ffea85, 20%);

/***********************************************************************/
/*==== TYPOGRAPHY =====================================================*/
/***********************************************************************/
$font-bold: "Montserrat Alternates", sans-serif;
$font-medium: "Montserrat Alternates", sans-serif;
$font-normal: "Nunito Sans", sans-serif;
$font-light: "Nunito Sans", sans-serif;

/* Font Weights */
$weight-thin: 100;
$weight-normal: 300;
$weight-medium: 500;
$weight-bold: 700;

// Set font-family that will be used for titles, text, buttons, and nav.
$font-heading: $font-bold;
$font-text: $font-normal;
$font-btn: $font-normal;
$font-nav: $font-light;

// Header height
$header-height: 55px;
