.housing-info .application-preview-container {
  transition: all 1s;
}

.housing-info {
  .inline-input {
    font-size: 16px;
    font-size: 1rem;
    font-weight: normal;
    line-height: 1.5;
    font-family: "Nunito Sans", sans-serif;
    color: #000105;
    margin-top: 0px;
    margin-bottom: 30px;
    text-transform: none;
    width: 75%;
  }
}
