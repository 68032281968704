@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.EmploymentInfo__reference {
  @include md() {
    .form-group {
      margin-bottom: 10px;
    }
  }
}
h4 {
  margin-bottom: 5px;
}

.EmploymentInfo__box {
  position: relative;
  img {
    position: absolute;
    top: 5%;
    right: 1%;
  }
  .current-employment {

    @include sm(){
      flex-direction: column;
      align-items: flex-start;

    }
  }
  button.btn.trash {
    position: absolute;
    top: -50px;
    right: 0;
  }
}
