@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.carousel {


}

.profileCompletionCont {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 0.75fr;
  grid-template-rows: 1fr;
  gap: 0 0;
  grid-template-areas:
    ". .";
}

.carouselContainer {
  position: relative;
  overflow-x: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 5px 0 10px 0;
  grid-auto-flow: column;

  ::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .viewProfile {
    display: flex;
    align-items: center;
    font-size: 1.1em;
    user-select: none;
    line-height: 1.5;
    font-weight: $weight-bold;
    text-transform: lowercase;

    .rotate-btn {
      background-image: url(../../assets/svg/chevron-left.svg);
      transition: none;
      transform: rotate(180deg);
    }

  }
}

.forwardContainer {
  position: sticky;
  right: 2px;
  top: 25%;
  height: 66px;
  width: 50px;
  background-color: rgba(18, 18, 18, 0.49);
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
  :hover, :Active {
    background-color: rgba(18, 18, 18, 0.55);
    .forward {
      filter: brightness(0) saturate(100%) invert(85%) sepia(31%) saturate(4062%) hue-rotate(350deg) brightness(109%) contrast(92%);
    }
  }
  .forward {
    position: absolute;
    top: calc(50% - 12px); // 1/2 Height of icon
    right: calc(50% - 12px);
    user-select: none;
  }
}
.backwardContainer {
  position: sticky;
  z-index: 100;
  left: 0;
  top: 25%;
  height: 66px;
  width: 50px;
  background-color: rgba(18, 18, 18, 0.49);
  border: 1px solid rgba(255, 255, 255, 0.7);
  border-radius: 5px;
  cursor: pointer;
  user-select: none;

  :hover, :Active {
    background-color: rgba(18, 18, 18, 0.55);

    .backward {
      filter: brightness(0) saturate(100%) invert(85%) sepia(31%) saturate(4062%) hue-rotate(350deg) brightness(109%) contrast(92%);
    }
  }

  .backward {
    position: absolute;
    top: calc(50% - 12px); // 1/2 Height of icon
    left: calc(50% - 12px);
    user-select: none;
  }

}