@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.Payment {
  @include full-box();
  padding: 50px 50px;
  p {
    margin-bottom: 40px;
  }

  .cc-err {
    margin: 20px;
    color: #dc3545;
  }

  .order-summary__details {
  }

  #coupon {
    width: 50% !important;
  }
}
.subText {
  margin-bottom: 10px;
}
