button:focus {
  outline: 0 !important;
}
.btn {
  display: inline-flex;
  flex-grow: 0;
  align-items: center;
  justify-content: center;
  width: auto;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @include font-size(18);
  line-height: 1.5;
  font-weight: $weight-bold;
  text-transform: lowercase;
  background-color: transparent;
  border: none;
  border-radius: 0px;
  padding: 0px 15px;
  @include transition();
  @include appearance();

  @include md() {
    @include font-size(16);
  }
  &.coming-soon {
    pointer-events: none;
    position: relative;
    .disabled {
      opacity: 0.2;
    }
    .comingSoon {
      position: absolute;
      top: 25px;
      right: 20%;
      background-color: $primary-color;
      border-radius: 16px;
      padding: 5px 50px;
      color: white;
      transform: rotate(-10deg);
      .comingText {
        color: white;
        padding-top: 0px;
        padding-bottom: 0px;
      }
    }
  }
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    outline: 0;
    cursor: pointer;
    color: rgb(113, 108, 108);
  }

  svg {
    height: 50%;
    width: auto;
  }
}

.btn--icon-right {
  i,
  svg {
    margin-left: 10px;
  }
}

.btn--icon-left {
  i,
  svg {
    margin-right: 10px;
  }
}

/* Primary Button */
.btn--primary,
.btn--primary:visited {
  height: 48px;
  min-width: 200px;
  line-height: 43px !important;
  border-radius: 48px;
  background: linear-gradient(
    to right,
    rgba(140, 201, 71, 1) 0%,
    rgba(115, 166, 58, 1) 50%,
    rgba(140, 201, 71, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#73a63a', endColorstr='#8cc947', GradientType=1);
  background-size: 200% auto;
  background-position: right center;
  border: none;
  color: $white;
  padding: 0 20px;
  box-shadow: none;

  @include md() {
    min-width: 170px;
  }

  @include xs() {
    min-width: 150px;
    font-size: 12px;
    max-height: 40px;
  }

  /* Hover */
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    color: $white !important;
    background-position: left center !important;
    @include box-shadow();
  }

  /* Click */
  &:focus {
    color: $white !important;
    background-position: left center !important;
    box-shadow: none;
  }
}

.btn--secondary,
.btn--secondary:visited {
  height: 48px;
  min-width: 200px;
  line-height: 43px;
  border-radius: 48px;
  background: $black;
  border: none;
  color: $white;
  padding: 0 20px;
  box-shadow: none;

  @include md() {
    min-width: 170px;
  }

  @include xs() {
    min-width: 150px;
    font-size: 14px;
    max-height: 40px;
  }

  svg {
    color: $white;
    fill: $white;
  }

  /* Hover */
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    background-color: $primary-color;
    color: $white;
    @include box-shadow();
  }

  /* Click */
  &:focus {
    background-color: $primary-color;
    box-shadow: none;
  }
}

.btn--secondary-alt,
.btn--secondary-alt:visited {
  height: 48px;
  min-width: 200px;
  line-height: 41px;
  border-radius: 48px;
  background: $white;
  border: 2px solid $black;
  color: $black;
  padding: 0 20px;
  box-shadow: none;

  @include md() {
    min-width: 170px;
  }

  @include xs() {
    min-width: 150px;
    font-size: 14px;
    max-height: 40px;
  }

  /* Hover */
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    border-color: $primary-color;
    color: $primary-color;
    @include box-shadow();
  }

  /* Click */
  &:focus {
    border-color: $primary-color;
    color: $primary-color;
    box-shadow: none;
  }
}

.btn--back,
.btn--back:visited {
  height: 48px;
  line-height: 44px;
  background: $white;
  color: $black;
  padding: 0 20px 0px 50px;
  box-shadow: none;
  position: relative;
  border: 2px solid $border-color;
  border-radius: 48px;

  &:before {
    content: "";
    background-image: url(../../svg/chevron-left.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    position: absolute;
    top: 12px;
    left: 20px;
    height: 20px;
    width: 20px;
    @include transition();
  }

  &.btn--small {
    min-width: auto;
    line-height: 30px;
    &:before {
      top: 7px;
    }
  }

  /* Hover */
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    border-color: $black;

    &:before {
      left: 12px;
    }
  }

  /* Click */
  &:focus {
    border-color: $black;
    &:before {
      left: 12px;
    }
  }
}

.btn--back-alt,
.btn--back-alt:visited {
  height: 48px;
  line-height: 44px;
  background: $white;
  color: $black;
  padding: 0 00px 0px 30px;
  box-shadow: none;
  position: relative;
  border: none;
  border-radius: 48px;

  &:before {
    content: "";
    background-image: url(../../svg/chevron-left.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left center;
    position: absolute;
    top: 12px;
    left: 0px;
    height: 20px;
    width: 20px;
    @include transition();
  }

  &.btn--small {
    min-width: auto;
    line-height: 30px;
    &:before {
      top: 7px;
    }
  }

  /* Hover */
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    &:before {
      left: -12px;
    }
  }

  /* Click */
  &:focus {
    &:before {
      left: -12px;
    }
  }
}

.btn--edit,
.btn--edit:visited {
  @include circle(48px);
  line-height: 41px;
  background: $black;
  border: 2px solid $black;
  color: $black;
  box-shadow: none;
  background-image: url(../../svg/pencil.svg);
  background-size: 30px 30px;
  background-position: center;
  background-repeat: no-repeat;

  /* Hover */
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    border-color: $primary-color;
    color: $primary-color;
    @include box-shadow();
  }

  /* Click */
  &:focus {
    border-color: $primary-color;
    color: $primary-color;
    box-shadow: none;
  }
}

.btn--download,
.btn--download:visited {
  height: 28px;
  min-width: 28px;
  line-height: 23px;
  border-radius: 28px;
  background: $black;
  border: none;
  color: $white;
  padding: 0 8px;
  box-shadow: none;

  svg {
    color: $white;
    fill: $white;
  }

  /* Hover */
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    background-color: $primary-color;
    @include box-shadow();
  }

  /* Click */
  &:focus {
    background-color: $primary-color;
    box-shadow: none;
  }
}

.btn--accent,
.btn--accent:visited {
  height: 40px;
  min-width: 130px;
  line-height: 40px;
  border-radius: 40px;
  @include gradient();
  border: none;
  color: $white;
  padding: 0 20px;
  box-shadow: none;

  font-family: "Montserrat Alternates", sans-serif;
  font-weight: $weight-thin;

  /* Hover */
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    background-color: $primary-color;
    color: $black;
    @include box-shadow();
  }

  /* Click */
  &:focus {
    background-color: $primary-color;
    box-shadow: none;
  }
}

.btn--danger,
.btn--danger:visited {
  height: 48px;
  line-height: 43px;
  border-radius: 48px;
  border: none;
  color: $white;
  padding: 0 20px;
  box-shadow: none;
  background: linear-gradient(
    to right,
    rgba(197, 74, 3, 1) 50%,
    rgba(245, 119, 48, 1) 100%
  );

  /* Hover */
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    color: $white;
    @include box-shadow();
    background: linear-gradient(
      to right,
      rgba(245, 119, 48, 1) 20%,
      rgba(197, 74, 3, 1) 100%
    );
  }

  /* Click */
  &:focus {
    background-color: $red-dark;
    box-shadow: none;
  }
}

.btn--filter {
  flex-shrink: 1;
  background-color: #FFF;
  border: 2px solid  #e0e0e0;
  color: rgba(0, 1, 5, 0.7);
  font-size: 15px;
  white-space: nowrap;
}

.isActive {
  /* isActive */
  color: #000105 !important;
  background-color: #e7e7e7 !important;
  @include transition();
}

.btn--filter,
.btn--filter:visited {
  height: 48px;
  border-radius: 48px;
  padding: 0 20px;
  box-shadow: none;
  font-family: $font-bold;

  &:hover,
  &:not(:disabled):not(.disabled) {
    color: #000105;
    background-color: #FFF;
  }

}

.btn--moreFilters {
  font-family: "Montserrat Alternates", sans-serif;
  font-size: 15px;
  white-space: nowrap;
  height: 48px;
  line-height: 43px;
  border-radius: 48px;
  background: $black;
  border: none;
  color: $white;
  width: auto;
  padding: 0 20px;
  box-shadow: none;
}

.btn--question,
.btn--question:visited {
  height: 40px;
  line-height: 40px;
  border-radius: 40px;
  border: none;
  color: $black;
  padding: 0px;
  font-family: $font-heading;
  font-weight: $weight-thin;
  text-decoration: underline;
  position: relative;
  padding-left: 50px;

  &:before {
    content: "?";
    color: $white;
    @include circle(40px);
    @include font-size(24);
    text-align: center;
    line-height: 40px;
    background: $black;
    position: absolute;
    left: 0;
    top: 0;
    @include transition();
  }

  /* Hover */
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    color: $primary-color;

    &:before {
      background: $primary-color;
    }
  }

  /* Click */
  &:focus {
    color: $primary-color;
  }
}

.btn-group {
  @include flex-row(flex-start);
  flex-wrap: wrap;
  margin-bottom: -10px;

  @include xs() {
    justify-content: center;
  }

  .btn {
    margin-bottom: 10px;
    &:not(:last-of-type) {
      margin-right: 40px;
      @include md() {
        margin-right: 20px;
      }

      @include xs() {
        margin-right: 6px;
      }
    }
  }
}

/* Small Btn */
.btn--small,
.btn--small:visited {
  height: 38px;
  min-width: 200px;
  line-height: 35px;
  @include font-size(16);
}

.btn--shrink,
.btn--shrink:visited {
  min-width: unset !important;
}

/* Switch Btn */
.btn--switch,
.btn--switch:visited {
  position: relative;
  display: block;
  height: 20px;
  width: 44px;
  vertical-align: top;
  margin-top: 2px;
  border: 1px solid $black;
  background: $primary-color;
  border-radius: 20px;
  @include transition();

  &:after {
    content: "";
    position: absolute;
    height: 22px;
    width: 22px;
    border-radius: 24px;
    border: 1px solid $black;
    background: $white;
    top: -3px;
    right: -1px;
    @include transition();
  }

  &:hover:after {
    background: #e1e1e1;
  }

  &.btn--switch-inactive {
    background: $secondary-color-light;

    &:after {
      right: unset;
      left: -1px;
    }
  }
}

.btn--toggle,
.btn--toggle:visited {
  @include circle(38px);
  line-height: 43px !important;
  color: $white;
  box-shadow: none;
  background-color: $white;
  background-image: url("../../svg/chevron-left.svg");
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: center;
  transform: rotate(90deg);

  /* Hover */
  &:hover,
  &:active,
  &:not(:disabled):not(.disabled):active {
    color: $white !important;
    @include box-shadow();
  }

  /* Click */
  &:focus {
    color: $white !important;
    @include box-shadow();
  }

  &.btn--toggle-inverse {
    transform: rotate(270deg);
  }
}
