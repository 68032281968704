@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";
.forgotPassword {
  height: calc(100vh - 50px);
  .ForgotPassword__container {
    width: 90%;
    height: auto;
    min-height: calc(50vh - 165px);
    margin: 110px auto 55px auto;
    padding: 80px 50px;
    border-radius: 20px;
    box-shadow: 0px 5px 22px 0px rgb(0 1 5 / 10%);
  }

  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;
  }
}
.errorMessage {
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  font-family: "Montserrat Alternates", sans-serif;
  text-transform: lowercase;
}

.loadingMessage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  font-family: "Montserrat Alternates", sans-serif;
  text-transform: lowercase;

  &.success {
    background-color: $primary-color;
    color: white;
    padding: 16px;
    border-radius: 20px;
    box-shadow: 0px 5px 22px 0px rgb(0 1 5 / 10%);
  }
}

.form_group {
  display: block;
  height: 35px;
  width: 100%;
  line-height: 35px;
  padding: 0 20px;
  color: #000105;
  border: 1px solid #e0e0e0;
  border-radius: 35px;
  transition: all 200ms ease-in-out;
  margin-bottom: 2em;
}
.MyAccountHeader {
  @include full-width();
  padding-top: $header-height + 40px;
  padding-bottom: 10px;
  border-bottom: 1px solid $border-color;
  margin-bottom: 60px;

  h1 {
    @include font-size(50);
  }
}
.MyAccount__container {
  @include full-width();
  border-radius: 16px;
  @include box-shadow();
  padding: 40px 30px;
}
.MyAccountInfo {
  margin-bottom: 40px;
}

.MyAccountPaymentMethod {
  margin-bottom: 40px;
}

.payment-method-card {
  width: 100%;
  max-width: 300px;
  height: 170px;
  border: 1px solid $border-color;
  border-radius: 16px;
  @include box-shadow();
  padding: 40px 20px;
  @include flex-column();
  background: #fafafa;
  position: relative;

  .btn--edit {
    position: absolute;
    top: -10px;
    right: -10px;
    @include circle(40px);
    background-size: 25px 25px;
    @include box-shadow();
  }

  .payment-method-card__label {
    @include font-heading();
    @include font-size(26);
    margin-bottom: 20px;
  }
  .payment-method-card__number {
    @include font-heading();
    @include font-size(20);
    margin-bottom: 20px;
  }
  .payment-method-card__details {
    @include flex-row();
    @include space-x(30px);

    .payment-method-card__exp,
    .payment-method-card__cvv {
      background: $secondary-color-light;
      padding: 5px 10px;
      border-radius: 10px;
      @include flex-row();
      @include space-x(10px);
    }
  }
}
.payment-method-edit-form {
  width: 100%;
  max-width: 400px;
  border: 1px solid $border-color;
  border-radius: 16px;
  @include box-shadow();
  padding: 40px 20px;
  background: #fafafa;
  position: relative;
}
.MyAccount__delete-account-btn {
  color: $danger-color;

  &:hover {
    color: $red-dark;
  }
}
