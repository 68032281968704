@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.Signup {
  .col-lg-4 {
    margin: 7px;
  }
  .row {
    position: relative;
    .passwordToggle {
      position: absolute;
      cursor: pointer;
      top: 35%;
      right: -25px;
      width: 20px;
      height: 20px;
      z-index: 999;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  @include full-box();

  .Signup__checkbox-container {
    .form-group:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }

  .ProgressBar__container {
    margin-left: 0;
    align-items: left;
  }
  .progress {
    display: flex;
    align-self: left;
  }

  .alreadySigned {
    margin-top: 40px;
  }
  .ProgressBar__container {
    margin-left: 0;
    align-items: left;
  }
  .progress {
    display: flex;
    align-self: left;
  }

  .Signup__recaptcha-container {
    margin-bottom: 40px;
  }
  .dropdown {
    position: relative;
    user-select: none;

    margin-top: 10%;
    .dropdown-btn {
      font: $font-normal;
      padding: 10px;
      border: 1px solid #e0e0e0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .imgContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 10px;
        width: 10px;
        margin-left: 5px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .dropdown-content {
      position: absolute;
      top: 110%;
      left: 0;
      border: 1px solid #e0e0e0;
      background: white;
      border-top: none;
      width: 100%;
      padding: 10px;
      z-index: 1;
      .dropdown-item {
        padding: 10px;
        cursor: pointer;
        transition: all 0.2s;

        &:hover {
          background: $grey;
          color: white;
        }
      }
    }
  }

  .err-msg-wrapper {
    display: flex;
    flex-direction: row;
    align-content: center;
  }

  .text-danger {
    color: #dc3545;
    font-size: 1rem;
    margin: 0 0 10px 0;
  }

  .choose-input-group {
    width: auto;
    min-width: 80px;
    height: auto;
    line-height: 14px;
    border-radius: 40px;
    padding: 13px 20px;
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 700;
    font-family: "Montserrat Alternates", sans-serif;
    color: #000105;
    border: 2px solid #e5e5e5;
    background: #e5e5e5;
    transition: all 200ms ease-in-out;
    text-align: center;
    cursor: pointer;
    margin-bottom: 0px;

    &:hover {
      background-color: $primary-color-light;
      border-color: $primary-color-light;
    }
    &.active {
      background-color: $primary-color-light;
      border-color: $primary-color-light;
    }
  }
}
