@import "../../../assets/sass/common/variables";
.centerText {
  text-align: center;
  border: 1px solid $secondary-color;
  padding: 1em;
  border-radius: 16px;
  background-color: lighten($yellow, 12%);
}
.smallHr {
  margin: 30px 0px;
}
