@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.LLPaid {
  @include full-box();

  .LLPaid__feature-wrapper {
    padding-bottom: 30px;
    height: 100%;
  }
  .LLPaid__feature {
    color: $black;
    @include font-heading();
    @include weight-bold();
    @include font-size(20);
    position: relative;
    line-height: 1;
    @include flex-row(flex-start, flex-start);
    @include space-x(10px);
    border: 1px solid $border-color;
    background: rgba($secondary-color, 0.1);
    border-radius: 16px;
    padding: 20px;
    height: 100%;


    h4 {
      margin-top: 0px;
    }
    p {
      margin-bottom: 0px;
    }
  }
}