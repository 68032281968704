@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.Footer {
  background: #F0F0F1;
  padding: 20px 0px;
  @include font-size(14);
  color: #777777;

  a, a:visited {
    color: #777777;

    &:hover {
      color: $primary-color;
    }
  }

  nav {
    @include flex-row(flex-start);
    @include space-x(30px);
  }
}