@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.applicant-info {
  
  border-radius: 16px;

  .applicantContent {
    padding-left: 20px;
  }
  .applicant__name {
    @include font-size(48);

    margin-bottom: 10px;
    margin-top: 20px;
    
  }

  .applicant-info__contact-info-label {
    margin-bottom: 10px;
    font-weight: 700;
  }

  .applicant-info__contact-info-value {
    @include font-size(16);
    font-family: $font-text;
    margin-bottom: 40px;
    display: inline-block;
 
  }

  .applicant-info__attributes-container {
    @include flex-row();
    flex-wrap: wrap;
   justify-content: left;
  }


}
