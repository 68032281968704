.FlashMessage {
  position: fixed;
  bottom: 20px;
  right: 20px;
  @include flex-row();
  @include box-shadow();
  border:1px solid $border-color;
  border-radius: 16px;
  padding:15px 20px;
  background: $white;
  z-index: 999;
  @include font-size(18);
  //height: 0px;
  opacity: 0;
  //visibility: hidden;
  transform: translateX(100%);

  transition-property: transform, opacity;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;

  &:before {
    content: '';
    width: 20px;
    height: 20px;
    display: block;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    margin-right: 10px;
    background-image: url('../../svg/info-circle.svg');

  }

  span {
    max-width: 300px;
    display: inline-block;
  }

  &.open {
    opacity: 1;
    //visibility: visible;
    //height: unset;
    transform: translateX(0);
    transition-property: transform, opacity, visibility;
    transition-duration: 0.3s;
    transition-delay: 0.3s;
    transition-timing-function: ease-in-out;
  }

  @include xs(){
    @include font-size(16);
    padding:10px 15px;
    bottom: 10px;
    right: 10px;
  }

  &.FlashMessage--success {
    border-color: $primary-color;
    color: $white;
    background-color: $primary-color;

    &:before {
      background-image: url('../../svg/checkmark-circle.svg');
    }
  }

  &.FlashMessage--warning {
    border-color: $orange;
    color: $white;
    background-color: $orange;
    &:before {
      background-image: url('../../svg/warning.svg');
    }
  }

  &.FlashMessage--danger {
    border-color: $danger-color;
    color: $white;
    background-color: $danger-color;
    &:before {
      background-image: url('../../svg/x-circle.svg');
    }
  }
}