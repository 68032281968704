@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";
.resetPassword {
  height: calc(100vh - 50px);
  .ResetPassword__container {
    width: 90%;
    height: auto;
    min-height: calc(50vh - 165px);
    margin: 110px auto 55px auto;
    padding: 80px 50px;
    border-radius: 20px;
    box-shadow: 0px 5px 22px 0px rgb(0 1 5 / 10%);
  }

  h2 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ResetPassword__container {
    display: flex;
    flex-direction: column;

    justify-content: center;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5%;
  }

  .errorMessage {
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    font-family: "Montserrat Alternates", sans-serif;
    text-transform: lowercase;
    display: none;
    transition: all 0.3s ease-in-out;
  }

  input:invalid[focused="true"] ~ .errorMessage {
    display: block;
  }

  .loadingMessage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    font-family: "Montserrat Alternates", sans-serif;
    text-transform: lowercase;

    &.success {
      background-color: $primary-color;
      color: white;
      padding: 16px;
      border-radius: 20px;
      box-shadow: 0px 5px 22px 0px rgb(0 1 5 / 10%);
    }
  }

  .form_group {
    display: block;
    height: 35px;
    width: 100%;
    line-height: 35px;
    padding: 0 20px;
    color: #000105;
    border: 1px solid #e0e0e0;
    border-radius: 35px;
    transition: all 200ms ease-in-out;
    margin-bottom: 2em;
  }
  .valid {
    color: #8cc947;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    font-family: "Montserrat Alternates", sans-serif;
    text-transform: lowercase;
    transition: all 0.3s ease-in-out;
  }
  .invalid {
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    font-family: "Montserrat Alternates", sans-serif;
    text-transform: lowercase;
    transition: all 0.3s ease-in-out;
  }
}
