@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.ScreenApplicants {
  @include full-box();
padding: 50px 50px;
  .ScreenApplicants__tenant-info-container {
    border-radius: 16px;
    
    padding: 20px 30px;
    margin-bottom: 40px;

    .form-checkbox label {
      @include font-heading();
      @include weight-bold();
      @include font-size(20);
    }
  }

  .checkBtn {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 0px;
    margin: 20px;
  }

  .greyBtn {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 0px;
    margin: 20px;
  }

  .advancedFeatures {
    justify-content: flex-end;
    display: flex;
    flex-direction:row-reverse;
    vertical-align: middle;
    margin-right: 0;
    float: left;
    padding: 0px;
  }

  .advancedFtText{
    font-family: $font-heading;
    display: block;
    font-size: 1.25rem;
    padding-top: 15px;
  }

  .ScreenApplicants__tenant-info-container {
    position: relative;
    img {
      position: absolute;
      top: 1%;
      right: 2%;
    }
  }

  .ScreenApplicants__basic-feature {
    margin-bottom: 30px;
    color: $black;
    @include font-heading();
    @include weight-bold();
    @include font-size(20);
    position: relative;
    line-height: 1;
    @include flex-row(flex-start, flex-start);
    @include space-x(10px);
  }
}

.applicant-form-feature-container {
  @include full-width();
  @include flex-row(space-between, center);
  border: 1px solid $border-color;
  border-radius: 16px;
  padding: 10px 20px;

  &:not(:last-of-type){
    margin-bottom: 10px;
  }

  label {
    @include font-size(18);
    @include font-heading();
    margin-bottom: 0px;
  }
  button {
    flex-shrink: 0;
  }
}
