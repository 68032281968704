@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.CustomizeApplication {
  @include full-box();
  padding: 50px 50px;


 .application-preview-badge {
   margin-top: 0px;
   margin-bottom: 20px;
   margin-left: 0px;

 }
 .disabled {
  border: 1px solid #E0E0E0;
  border-radius: 16px;
  padding: 65px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
  transition: all 200ms ease-in-out;
  height: 85%;
  color: $border-color;
  @include sm() {
    padding: 100px 20px;
  }
}
 .comingSoon {
  position: absolute;
  top: 50px;
  right: 13%;
  background-color: $primary-color;
  border-radius: 16px;
  padding: 5px 50px;
  color: white; 
  transform: rotate(-10deg);
  @media screen and (max-width: 1285px) {
    top: 70px;
    right: 15%;
  }
  .comingText {
    color: white; 
    padding-top: 0px;
    padding-bottom: 10px;
    @media screen and (max-width: 1285px) {
      font-size: 1.2rem;
    }
  }
  @include md() {
    top: 28px;
    right: 25%;
  }
  @include sm() {
    top: 50px;
    right: 25%;
  }
}

 .btn--back {
  margin-bottom: 65px;
}

.form-checkbox__label {
  font-family: "Montserrat Alternates", sans-serif; 
}

.form-checkbox__details {
  padding-top: 10px;
}

  h3 {
    @include font-size(24);
    @include weight-bold();
    margin-bottom: 10px;
    margin-top: 20px;
  }


  .form-group {
    height: 100%;
    margin-bottom: 0px;
    padding-bottom: 40px;

    .form-checkbox {
      height: 100%;
    }
  }

  .CustomizeApplication__selections {
    margin-top: 80px;

    p {
      margin-bottom: 40px;
    }
  }

  .CustomizeApplication__premium-details {
    margin-bottom: 40px;

    h4 {
      @include font-size(20);
      margin-bottom: 10px;
      font-family: "Montserrat Alternates", sans-serif; 
    }
  }

  .CustomizeApplication__premium-options {
    > * {
      margin-bottom: 40px;
    }
  }
  .CustomizeApplication__premium-option {
    height: 100%;
    border: 1px solid $border-color;
    border-radius: 16px;
    padding: 30px 20px;
    @include flex-column(center, flex-start);
    background: rgba($secondary-color, 0.1);
    margin-bottom: 40px;
  }

  .textBox {
    padding: 30px 20px;
  }

 .poweredBox {
    padding-top: 0px;
    margin: 0 auto;
    padding-left: 0px;
  }
}