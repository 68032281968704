@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.VerifyEmailModal {

  .VerifyEmailModal__resend-message {
    @include flex-row(flex-start);
    @include space-x(10px);
    @include font-heading();
    @include weight-bold();
    @include font-size(20);
    margin-bottom: 40px;

    .VerifyEmailModal__resend-message__check {
      @include circle(30px);
      color: $white;
      background: $primary-color;
      margin-right: 20px;
    }
  }

  .VerifyEmailModal__resend {
    margin-bottom: 40px;

    .btn {
      @include font-size(14);
      text-decoration: underline;
      height: auto;
      padding: 0 10px;
    }
  }
}