@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.ApplicationInvite {
  @include full-box();
 padding: 50px 50px; 

  .btn--back {
    margin-bottom: 65px;
  }

  .inline-input {
    border-radius: 38px;
    border: 1px solid $border-color;
    padding: 0px 20px;
    min-height: 38px;
    flex-grow: 1;
    width: 100%;
  }

  .text-danger {
    color: #dc3545;
    font-size: 0.9rem;
  }
}
