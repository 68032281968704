@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.mainContent {
  padding: 0px !important;
  border-radius: 16px;
  border: 1px solid #e0e0e0;
  width: 100%;
  height: auto;
}

.info-title-container-p {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.bank-check-container {
  padding: 0 20px 20px 20px;
}

.statusPending {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-family: "Montserrat Alternates", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.4rem;
  letter-spacing: -0.015em;
}

.bank-check-item {
  margin-bottom: 30px;

  .bank-check-item__label {
    margin-bottom:  10px;
    font-weight: 700;
    text-transform: lowercase;
  }
}
