@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.ScreenApplicants {
  @include full-box();
padding: 50px 50px;
  .order-summary__order-item {
    display: flex;
    flex-direction: row;
  }

  h1 {
    padding-bottom: 40px;
  }

  .btn--back {
    margin-bottom: 65px;
  }

  .comingSoon {
    position: absolute;
    background-color: $primary-color;
    border-radius: 16px;
    padding: 5px 10px;
    color: white;
    transform: rotate(-10deg);
    font-size: 1.2rem;

    .comingText {
      color: white;
    }
  }

  

  .ScreenApplicants__tenant-info-container {
    border-radius: 16px;
    border: 1px solid $border-color;
    padding: 20px 30px;
    margin-bottom: 40px;

    .form-checkbox label {
      @include font-heading();
      @include weight-bold();
      @include font-size(20);
    }
  }

  .order-summary {
    position: sticky;
    top: 55px;
  }

  .ScreenApplicants__basic-feature {
    margin-bottom: 30px;
    color: $black;
    @include font-heading();
    @include weight-bold();
    @include font-size(20);
    position: relative;
    line-height: 1;
    @include flex-row(flex-start, flex-start);
    @include space-x(10px);
  }

  .choose-input-group {
    width: auto;
    min-width: 80px;
    height: 35px;
    line-height: 14px;
    border-radius: 40px;
    padding: 0px 20px;
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 700;
    font-family: "Montserrat Alternates", sans-serif;
    color: #000105;
    border: 2px solid #e5e5e5;
    background: #e5e5e5;
    transition: all 200ms ease-in-out;
    text-align: center;
    cursor: pointer;
    margin-bottom: 0px;

    &:hover {
      background-color: $primary-color-light;
      border-color: $primary-color-light;
    }
    &.active {
      background-color: $primary-color-light;
      border-color: $primary-color-light;
    }
  }
}
