@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";
.Guarantor {
  @include full-box();
  padding: 50px 50px;
}
.Guarantor__container {
  @include full-width();
  padding: 20px;
  border-radius: 16px;
  border: 1px solid $border-color;

  @include sm(){
    p {text-align: center;}
  }

  &:not(:last-of-type){
    margin-bottom: 40px;
  }

  .Guarantor__emails {
    @include flex-row(flex-start);
    flex-wrap: wrap;
    @include space-x(20px);
    margin-bottom: 20px;

  }

  .Guarantor__link {
    @include font-size(25);
    line-height: 1;
    font-family: $font-text;
    font-weight: $weight-bold;
    color: $black;
    margin-bottom: 0px;
    margin-top: 0px;
    text-transform: none;
    @include flex-row(flex-start);
    @include space-x(20px);

    @include sm(){
      flex-direction: column;
      @include space-y(20px);
      @include space-x(0px);
    }

    a {
      text-decoration: underline;
    }

    button {
      background: $border-color;
      border-radius: 16px;
    }
  }
}