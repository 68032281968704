@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.BasicModal {
  .modal__container {

    h1 {
      margin-bottom: 20px;
    }

    h2 {
      margin-bottom: 40px;
    }

    h3 {
      text-align: left;
      margin-bottom: 0px;
      @include weight-bold();
    }

    p {
      text-align: left;
    }
  }

  .BasicModal__modal__details {
    margin-bottom: 40px;
  }
}