@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.LandlordLanding {
  @include full-width();
  @include splash-padding();

  h1 {
    @include font-size(60);
    line-height: 120%;
    margin-bottom: 40px;
  }
  .subText {
    margin-bottom: 60px;
  }

  h4 {
    font-family: "Montserrat Alternates";
  }
  .btn-screening {
    margin-bottom: 40px;
    height: 40px;
    font-size: 16px;
  }
  .LandlordLanding__splash-img {
    @include full-width();
    transform: scale(1.2);
    position: relative;
    top: -40px;

    @include md {
      top: 0;
    }
  }

  .LandlordLanding__details-container {
    @include full-width();
    padding: 50px 50px 30px 50px;
    margin-top: -40px;
    border-radius: 16px;
    @include box-shadow();
  }

  .LandlordLanding__details {
    @include flex-row(space-between);
    flex-wrap: wrap;
    align-items: flex-start;
    @include sm {
      flex-direction: column;
    }
  }

  .LandlordLanding__details-item {
    width: calc(50% - 40px);
    height: auto;
    @include flex-row(flex-start, flex-start);
    margin-bottom: 40px;
    @include sm {
      width: calc(50% - 20px);
      margin-right: 0;
    }

    .LandlordLanding__details-item__svg {
      width: 60px;
      height: 60px;
      margin-right: 20px;
      @include sm {
        margin-right: 0;
        flex-direction: column;
      }
    }
  }
}
