@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.LandlordReferenceThankYou {
  @include full-box();
  @include flex-column();

  img {
    @include full-width();
    object-fit: contain;
    object-position: center;
  }
}