@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.premium-feature-locked {
  @include full-width();
  border-radius: 16px;
  border: 1px solid $border-color;
  padding: 20px 60px;

  .title-container {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  h2 {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .premium-feature-locked__icon {
    width: 25px;
    height: 30px;
    background-image: url(../../../assets/svg/lock.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .premium-feature-locked__title {
    color: $danger-color;
    @include font-heading();
    @include font-size(18);

  }
}