@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.TenantPremiumFeatures {
  @include full-box();
 padding: 50px 50px;
  .TenantPremiumFeatures__container {
    @include space-y(30px);
  }
}
.premium-feature {
  padding: 30px 20px;
  border-radius: 16px;
  @include box-shadow();

  .info-title-container {
    margin-bottom: 0px;
  }
}