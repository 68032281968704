/* Responsive Breakpoints */
/* These match the grid.scss */

// Extra Large devices (desktops, 1200px and up)
@mixin xl() {
  @media (max-width: 1399.98px) {
    @content ;
  }
}

// Large devices (desktops, 992px and up)
@mixin lg() {
  @media (max-width: 1199.98px) {
    @content;
  }
}

// Medium devices (tablets, 768px and up)
@mixin md() {
  @media (max-width: 991.98px) {
    @content;
  }
}

// Small devices (landscape phones, 576px and up)
@mixin sm() {
  @media (max-width: 767.98px) {
    @content;
  }
}

// Extra small devices (portrait phones, less than 576px)
@mixin xs() {
  @media (max-width: 575.98px) {
    @content;
  }
}

/* Font to remy */
@mixin font-size($pxval: 16) {
  font-size: $pxval * 1px;
  font-size: ($pxval / 16) * 1rem;
}

/* Flex shorthand */
@mixin flex-row($justify: center, $align: center) {
  display: flex;
  flex-direction: row;
  justify-content: $justify;
  align-items: $align;
}

@mixin flex-row-reverse($justify: center, $align: center) {
  display: flex;
  flex-direction: row-reverse;
  justify-content: $justify;
  align-items: $align;
}

@mixin flex-column($justify: center, $align: center) {
  display: flex;
  flex-direction: column;
  justify-content: $justify;
  align-items: $align;
}

@mixin flex-column-reverse($justify: center, $align: center) {
  display: flex;
  flex-direction: column-reverse;
  justify-content: $justify;
  align-items: $align;
}

/* Space between flexed items horizontally */
@mixin space-x($pxv) {
  > * + * {
    margin-left: $pxv;
  }
}

/* Space between flexed items vertically */
@mixin space-y($pxv) {
  > * + * {
    margin-top: $pxv;
  }
}

/* Apply a text shadow */
@mixin text-shadow() {
  text-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}

/* Add extra padding to splash sections that passes the header */
@mixin splash-padding() {
  padding: 50px + $header-height 0px 50px 0px;
}

/* Makes a section full width with auto height */
@mixin full-width() {
  width: 100%;
  height: auto;
}

/* Makes an element take up the full width and height of parent. useful. for column components. like features blog posts */
@mixin full-height() {
  width: 100%;
  height: 100%;
}

/* Makes a section full width with full page height */
@mixin full-page() {
  width: 100%;
  height: auto;
  min-height: 100vh;
}

/* Make a section the rent panda style boxed section */
@mixin full-box() {
  width: 90%;
  height: auto;
  min-height: calc(100vh - 165px);
  margin: 55px + $header-height auto 55px auto;
  padding: 80px 50px;
  border-radius: 20px;
  @include box-shadow();

  @include md(){
    box-shadow: none;
    padding: 0px 0px;
    width: 100%;
  }
}

/* Makes a circle */
@mixin circle($px) {
  width: $px;
  height: $px;
  border-radius: $px;
  flex-shrink: 0;
}

/* Apply a box shadow to an element */
@mixin box-shadow($distance: 5px, $blur: 22px, $opacity: 0.10) {
  -webkit-box-shadow: 0px $distance $blur 0px rgba(0, 1, 5, $opacity);
  -moz-box-shadow: 0px $distance $blur 0px rgba(0, 1, 5, $opacity);
  box-shadow: 0px $distance $blur 0px rgba(0, 1, 5, $opacity);
}

/* CSS Transitions effects. Apply to elements that change on hover, scroll, etc */
@mixin transition($property: all, $duration: 200ms) {
  -webkit-transition: $property $duration ease-in-out;
  -moz-transition: $property $duration ease-in-out;
  -ms-transition: $property $duration ease-in-out;
  -o-transition: $property $duration ease-in-out;
  transition: $property $duration ease-in-out;
}

/* Appearance (removes default style put on elements by browsers) */
@mixin appearance($appearance: none) {
  -webkit-appearance: $appearance;
  -moz-appearance: $appearance;
}

/* Gradients */
@mixin gradient() {
  background: rgb(115, 166, 58);
  background: -moz-linear-gradient(90deg, rgba(115, 166, 58, 1) 0%, rgba(140, 201, 71, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(115, 166, 58, 1) 0%, rgba(140, 201, 71, 1) 100%);
  background: linear-gradient(90deg, rgba(115, 166, 58, 1) 0%, rgba(140, 201, 71, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#73a63a", endColorstr="#8cc947", GradientType=1);
}

/************************************************************************
== QUICK STYLES =========================================================
************************************************************************/
@mixin font-heading() {
  font-family: $font-heading;
}

@mixin font-text() {
  font-family: $font-text;
}

@mixin font-btn() {
  font-family: $font-btn;
}

@mixin weight-bold() {
  font-weight: $weight-bold;
}

@mixin weight-normal() {
  font-weight: normal;
}

@mixin weight-thin() {
  font-weight: $weight-thin;
}

@mixin text-center() {
  text-align: center;
}

@mixin text-left() {
  text-align: left;
}

@mixin text-right() {
  text-align: right;
}

@mixin text-uppercase() {
  text-transform: uppercase;
}

@mixin text-capitalize() {
  text-transform: capitalize;
}

@mixin text-normal() {
  text-transform: none;
}
