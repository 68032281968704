.circle-check {
  display: inline-block;
  @include circle(35px);
  background-color: $primary-color;
  background-image: url(../../svg/checkmark.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  flex-shrink: 0;
}
.circle-check--small {
  display: inline-block;
  @include circle(24px);
  background: $primary-color;
  background-image: url(../../svg/checkmark.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px 12px;
  flex-shrink: 0;
}
.circle-check--tiny {
  display: inline-block;
  @include circle(16px);
  background: $primary-color;
  background-image: url(../../svg/checkmark.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8px 8px;
  flex-shrink: 0;
}

.circle-exclamation {
  display: inline-block;
  @include circle(35px);
  background-color: $danger-color;
  text-align: center;
  color: $white;
  @include font-size(24);
  line-height: 35px;
  @include font-heading();
  flex-shrink: 0;

  &:before {
    content: '!';
  }
}
.circle-x {
  display: inline-block;
  @include circle(35px);
  background-color: $danger-color;
  background-image: url(../../svg/x.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;
  flex-shrink: 0;
}
.circle-x--small {
  display: inline-block;
  @include circle(24px);
  background: $danger-color;
  background-image: url(../../svg/x.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px 12px;
  flex-shrink: 0;
}
.circle-x--tiny {
  display: inline-block;
  @include circle(16px);
  background: $danger-color;
  background-image: url(../../svg/x.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 8px 8px;
  flex-shrink: 0;
}


.circle-question {
  display: inline-block;
  @include circle(35px);
  background-color: $black;
  text-align: center;
  color: $white;
  @include font-size(24);
  line-height: 35px;
  @include font-heading();
  flex-shrink: 0;

  &:before {
    content: '?';
  }
}

.box-check {
  width: 20px;
  height: 20px;
  background: $primary-color;
  border-radius: 4px;
  background-image: url(../../svg/checkmark.svg);
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: center;
  flex-shrink: 0;
}

.status-label {
  @include flex-row(flex-start);
  @include space-x(15px);
  color: $black;
  @include font-heading();
  @include font-size(16);

  &.status-label--warning {
    color: $danger-color;
  }

  &.status-label--inactive {
    color: $grey;
  }
}

.top-badge {
  border-top-left-radius: 13px;
  border-bottom-right-radius: 16px;
  background: $accent-color;
  color: $white;
  padding: 20px;
  @include font-size(20);
  @include font-heading();
  @include weight-bold();
  display: inline-block;
  width: auto;
}

// progress bar
.progress-bar {
  position: relative;
  margin-top: 20px;
  width: 100%;
  height: 20px;
  border-radius: 20px;
  background: $secondary-color-light;
  color: $white;
  text-align: center;
  line-height: 20px;

  .progress-bar__value {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    background: $secondary-color;
    border-radius: 20px;
  }

  .progress-bar__label {
    position: relative;
  }

  &.progress-bar--highest {
    .progress-bar__value {
      background: $primary-color;
    }

    .progress-bar__label {
      color: $white;
    }
  }

  &.progress-bar--high {
    .progress-bar__value {
      background: $primary-color-light;
    }

    .progress-bar__label {
      color: $black;
    }
  }

  &.progress-bar--medium {
    .progress-bar__value {
      background: $orange;
    }

    .progress-bar__label {
      color: $white;
    }
  }

  &.progress-bar--low {
    .progress-bar__value {
      background: $danger-color;
    }

    .progress-bar__label {
      color: $black;
    }
  }
}

.status--completed {
  display: inline-block;
  padding: 4px 20px;
  color: $white;
  @include font-heading();
  @include font-size(16);
  text-align: center;
  line-height: 20px;
  background: $primary-color;
  border-radius: 6px;
}
.status--incomplete {
  display: inline-block;
  padding: 4px 20px;
  color: $white;
  @include font-heading();
  @include font-size(16);
  text-align: center;
  line-height: 20px;
  background: $primary-color;
  border-radius: 6px;

}

.row--boxed {
  border: 1px solid $border-color;
  border-radius: 16px;
  margin-right: 0px!important;
  margin-left: 0px!important;
  padding: 20px 5px;
}

.checkedbox {
  width: 20px;
  height: 20px;
  background: $primary-color;
  border-radius: 4px;
  background-image: url(../../svg/checkmark.svg);
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: center;
  flex-shrink: 0;
}

.checkedcircle {
  width: 24px;
  height: 24px;
  border-radius: 20px;
  background: $primary-color;
  background-image: url(../../svg/checkmark.svg);
  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: center;
  flex-shrink: 0;
}