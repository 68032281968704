@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";


.credit-check-info {

  .credit-score-information {
    padding: 20px;
    padding-top: 0px;
  }
  .credit-score__title {
    @include font-heading();
  }

  .credit-score__scale-container {
    @include flex-row(center, flex-start);
    position: relative;
    padding-top: 16px;
    margin-bottom: 40px;

    &:before {
      content: '';
      background-image: url('../../../assets/svg/caret-down.svg');
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0px;
    }

    .credit-score__scale-section {
      flex-grow:1;
      @include font-size(15);

      &:first-of-type .credit--score__scale-section__pill {
        border-bottom-left-radius: 34px;
        border-top-left-radius: 34px;
      }
      &:last-of-type .credit--score__scale-section__pill {
        border-bottom-right-radius: 34px;
        border-top-right-radius: 34px;
      }

      .credit--score__scale-section__pill {
        height: 34px;
        width: 100%;
        padding: 0px 10px;
        text-align: center;
        @include flex-column();
        background: red;

        span {
          color: $black;
          opacity: 0.3;
        }
      }
      .credit--score__scale-section__label {
        margin-top: 10px;
        text-align: center;
        width: 100%;
        color: $black;
        opacity: 0.3;
      }

      &.credit-score__scale-section--poor .credit--score__scale-section__pill {
        background:  #FF8039;
      }
      &.credit-score__scale-section--fair .credit--score__scale-section__pill {
        background: #FFA674;
      }
      &.credit-score__scale-section--good .credit--score__scale-section__pill {
        background: #FFCCB0;
      }
      &.credit-score__scale-section--very-good .credit--score__scale-section__pill {
        background: $primary-color-light;
      }
      &.credit-score__scale-section--excellent .credit--score__scale-section__pill {
        background: $primary-color;
      }
    }
  }

  .credit-score--excellent {

    .credit-score__title {
      color: $primary-color;
    }

    .credit-score__scale-container:before {
      left: 88.5%;
    }

    .credit-score__scale-section--excellent {
      .credit--score__scale-section__pill span, .credit--score__scale-section__label {
        opacity: 1;
      }
    }
  }

  .credit-score--very-good {
    .credit-score__title {
      color: $primary-color;
    }
    .credit-score__scale-container:before {
      left: 68.5%;
    }
    .credit-score__scale-section--very-good {
      .credit--score__scale-section__pill span, .credit--score__scale-section__label {
        opacity: 1;
      }
    }
  }

  .credit-score--good {
    .credit-score__title {
      color: $orange;
    }
    .credit-score__scale-container:before {
      left: 48.5%;
    }
    .credit-score__scale-section--good {
      .credit--score__scale-section__pill span, .credit--score__scale-section__label {
        opacity: 1;
      }
    }
  }

  .credit-score--fair {
    .credit-score__title {
      color: #FFA674;
    }
    .credit-score__scale-container:before {
      left: 28.5%;
    }
    .credit-score__scale-section--fair {
      .credit--score__scale-section__pill span, .credit--score__scale-section__label {
        opacity: 1;
      }
    }
  }

  .credit-score--poor {
    .credit-score__title {
      color: #FF8039;
    }
    .credit-score__scale-container:before {
      left: 8.5%;
    }
    .credit-score__scale-section--poor {
      .credit--score__scale-section__pill span, .credit--score__scale-section__label {
        opacity: 1;
      }
    }
  }

  .credit-score-table {
    display: block;
    width: 100%;

    &:not(:last-of-type){
      margin-bottom: 60px;
    }

    thead {
      display: block;
      width: 100%;

      tr {
        display: flex;
        flex: 1 1 0px;
        justify-content: space-evenly;
        align-items: center;


        th {
          text-align: center;
          flex-grow: 1;
          width: 100%;
          padding: 15px;
          @include font-heading();
          @include font-size(14);
        }
      }
    }
    tbody {
      display: block;
      width: 100%;

      tr {
        display: flex;
        flex: 1 1 0px;
        justify-content: space-evenly;
        border: 1px solid $border-color;
        border-radius: 16px;
        align-items: center;

        &:not(:last-of-type) {
          margin-bottom: 10px;
        }

        td {
          padding: 15px;
          text-align: center;
          flex-grow: 1;
          width: 100%;
          @include font-size(14);

        }
      }
    }
  }
}