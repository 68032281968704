@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.CoApplicants {
  max-height: 320px;


  h4 {
    font-family: "Montserrat Alternates", sans-serif;
  }

  .CoApplicants__container {
    //margin-bottom: 20px;
    position: relative;
    z-index: 1;
     &:after,  {
       content: '';
       position: absolute;
       right: -15px;
       top: 0;
       bottom: 0;
       height: 100%;
       width: 15px;
       background: rgb(255,255,255);
       background: -moz-linear-gradient(90deg, rgba(255,255,255,0) 20%, rgba(255,255,255,1) 100%);
       background: -webkit-linear-gradient(90deg, rgba(255,255,255,0) 20%, rgba(255,255,255,1) 100%);
       background: linear-gradient(90deg, rgba(255,255,255,0) 20%, rgba(255,255,255,1) 100%);
       filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
       z-index: 2;
     }

    &:before,  {
      content: '';
      position: absolute;
      left: -15px;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 15px;
      background: rgb(255,255,255);
      background: -moz-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 80%);
      background: -webkit-linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 80%);
      background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 80%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
      z-index: 2;
    }

    //.carousel {
    //  margin: 0 15px;
    //}
    .flickity-viewport {
      padding: 0 15px;
      margin-right: -15px;
      margin-left: -15px;
    }

  }

  .CoApplicants__applicant {
    border: 1px solid $border-color;
    border-radius: 16px;
    padding: 15px;
    margin-left: 35px;
    min-width: 295px;

    .progress-bar {
      margin-top: 0;
      //margin-bottom: 10px;
    }
  }

  .info-title-container {
    margin-bottom: 8px;
  }

}
