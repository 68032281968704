/********** Text Select Color ******************************************/
::selection, ::-moz-selection {
  color: $white;
  background: $primary-color; /* WebKit/Blink Browsers */
}

body {
  font-family: $font-text;
  font-size: 16px;
  font-weight: normal;
  line-height: 1;
  color: $body-color;
  letter-spacing: -1.5%;
}

/***********************************************************************/
/********** Headings ***************************************************/
/***********************************************************************/
.title, .heading {
  font-family: $font-heading;
}

h1, .h1 {
  @include font-size(66);
  line-height: 1;
  font-family: $font-heading;
  font-weight: $weight-bold;
  color: $black;
  margin-bottom: 40px;
  letter-spacing: -0.015em;
  text-transform: lowercase;

  @include lg(){
    @include font-size(55);
  }

  @include md(){
    @include font-size(44);
  }

  @include xs(){
    @include font-size(30);
  }
}

h2, .h2 {
  @include font-size(30);
  line-height: 1.2;
  font-family: $font-heading;
  font-weight: $weight-bold;
  color: $black;
  margin-top: 0px;
  margin-bottom: 15px;
  letter-spacing: -0.015em;
  text-transform: lowercase;

  @include md(){
    @include font-size(24);
  }

  @include xs(){
    @include font-size(20);
  }
}

h3, .h3 {
  @include font-size(25);
  line-height: 1;
  font-family: $font-heading;
  font-weight: $weight-bold;
  color: $black;
  margin-bottom: 20px;
  margin-top: 10px;

  text-transform: none;

  @include md(){
    @include font-size(22);
  }

  @include xs(){
    @include font-size(18);
  }
}

h4, .h4 {
  @include font-size(20);
  line-height: 1;
  font-family: $font-normal;
  font-weight: $weight-bold;
  color: $black;
  margin-top: 10px;
  margin-bottom: 10px;

  text-transform: none;
}

h5, .h5 {
  @include font-size(16);
  line-height: 1;
  font-family: $font-text;
  font-weight: $weight-bold;
  color: $black;
  margin-top: 0px;
  margin-bottom: 8px;

  text-transform: none;
}

h6, .h6 {
  @include font-size(16);
  line-height: 1;
  font-family: $font-text;
  color: $text-color-primary;
  margin-bottom: 10px;
  letter-spacing: 0px;
  text-transform: none;
}

/***********************************************************************/
/*********** Paragraphs and Text Effects *******************************/
/***********************************************************************/
p, .p {
  @include font-size(16);
  @include weight-normal();
  line-height: 1.5;
  font-family: $font-text;
  color: $body-color;
  margin-top: 0px;
  margin-bottom: 20px;

  text-transform: none;
}

i, em {

}

b, strong {

}

small {

}

sup, sub {

}


/***********************************************************************/
/*********** Links *****************************************************/
/***********************************************************************/
a, a:visited {
  color: $black;
  text-decoration: none;
  outline: none;
  @include transition();
}

a:hover, a:focus, a:active {
  color: $primary-color;
  text-decoration: none;
  outline: none;
}

/***********************************************************************/
/********** Lines & Boxes **********************************************/
/***********************************************************************/
hr {
  height: 0px;
  margin: 20px*3 0px;
  border: none;
  border-bottom: 2px solid $secondary-color;
}

/***********************************************************************/
/********** Default Blockquote Styles **********************************/
/***********************************************************************/
blockquote {

  p {

  }

  cite {

    &::before {

    }
  }
}

/***********************************************************************/
/****** LISTS **********************************************************/
/***********************************************************************/
ol, ul {
  list-style-type: none;
  margin-bottom: 0px;
  padding-left: 0px;
}

main {
  ol, ul {
    list-style-position: inside;
    font-weight: normal;
  }
}
