@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";
.myAccount {
  .errorMessage {
    color: red;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    display: none;
  }
  input:invalid[focused="true"] ~ .errorMessage {
    display: block;
  }

  .form_group {
    display: block;
    height: 35px;
    width: 100%;
    line-height: 35px;
    padding: 0 20px;
    color: #000105;
    border: 1px solid #e0e0e0;
    border-radius: 35px;
    transition: all 200ms ease-in-out;
    margin-bottom: 2em;
  }
  .MyAccountHeader {
    @include full-width();
    padding-top: $header-height + 40px;
    padding-bottom: 10px;
    border-bottom: 1px solid $border-color;
    margin-bottom: 60px;

    h1 {
      @include font-size(50);
    }
  }
  .MyAccount__container {
    @include full-width();
    border-radius: 16px;
    @include box-shadow();
    padding: 40px 30px;
  }
  .MyAccountInfo {
    margin-bottom: 40px;
  }

  .MyAccountPaymentMethod {
    margin-bottom: 40px;
  }

  .payment-method-card {
    width: 100%;
    max-width: 440px;
    height: 170px;
    border: 1px solid $border-color;
    border-radius: 16px;
    padding: 40px 20px;
    @include flex-column();
    background: #fafafa;
    position: relative;
    margin-bottom: 30px;

    .btn--edit {
      position: absolute;
      top: -10px;
      right: -10px;
      @include circle(40px);
      background-size: 25px 25px;
      @include box-shadow();
    }

    .payment-method-card__label {
      @include font-heading();
      @include font-size(26);
      margin-bottom: 20px;
    }
    .payment-method-card__number {
      @include font-heading();
      @include font-size(20);
      margin-bottom: 20px;
    }
    .payment-method-card__details {
      @include flex-row();
      @include space-x(30px);

      .payment-method-card__exp,
      .payment-method-card__cvv {
        background: $secondary-color-light;
        padding: 5px 10px;
        border-radius: 10px;
        @include flex-row();
        @include space-x(10px);
      }
    }
  }
  .payment-method-edit-form {
    width: 100%;
    max-width: 440px;
    border: 1px solid $border-color;
    border-radius: 16px;
    padding: 40px 20px;
    background: #fafafa;
    position: relative;
  }
  .MyAccount__delete-account-btn {
    color: $danger-color;

    &:hover {
      color: $red-dark;
    }
  }
}
