@import "../../../Layout/global.scss";
.modalHead {
  h1.text {
    font-family: "Montserrat Alternates";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    margin-bottom: 2rem;
  }
  p.text {
    font-family: "Nunito Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27.28px;
    margin-bottom: 2rem;
    letter-spacing: -0.015em;
  }
  display: flex;
  align-items: center;
  margin: 0px 3em;
  flex-direction: column;
  max-width: 423px;
}
.modalBody {
  display: flex;
  align-items: center;
  margin: 0px 8em;
}
