@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";
.editLlReference {
  @include full-box();

  .info-title-container {
    margin-top: 65px;
  }

  .info__label {
    margin-bottom: 12px;
  }
}
