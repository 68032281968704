@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.id-verification__image {
  width: 100%;
  height: auto;
  max-width: 230px;
  margin-top: 30px;
  object-fit: contain;
}

.verified--container {
  display: flex;
  flex-direction: column;
  width: 90%;
  padding: 0 20px 20px 20px;
}

.show-checkMark {
  display: flex;
  margin: auto;
  font-family: 'Montserrat Alternates', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.40rem;
  line-height: 1.40rem;
  letter-spacing: -0.015em;
}

.verified--label {
  padding-left: 0.8rem;
  padding-bottom: 0.8rem;
}