@import "../../assets/sass/app.scss";
.modal {
  width: 100vw;
  height: auto;
  min-height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;

  .overlay {
    @include full-height();
    background: $black;
    opacity: 0.8;
    position: fixed;
    top: 0;
    left: 0;
  }

  .wrapper {
    width: 95%;
    position: relative;
    transform: translate(50vw, 50vh) translate(-50%, -50%);
  }

  .container {
    width: 100%;
    background: $white;
    height: auto;
    // min-height: 450px;
    padding: 50px;
    @include flex-column();
    text-align: center;
    position: relative;
    border-radius: 10px;
  }
}
