@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.VerifyInfoModal {

  &.modal .modal__wrapper {
    max-width: 850px;
  }

  .VerifyInfoModal__form-container {
    text-align: left;
  }
}