@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.AddressInformation {
  @include full-box();

  h1 {
    margin-bottom: 60px;
  }

  .nextBtn {
    height: auto;
    font-size: 16px;
    margin-top: 35px;
  }
  .autocomplete-dropdown-container {
    .suggestion-item,
    .suggestion-item--active {
      border-radius: 3px;
      @include box-shadow();
      border-bottom: 1px solid #e0e0e0;
      padding: 10px 5px;
      margin: 0;

      span {
        width: 100%;
        height: auto;
        @include font-size(16);
        padding: 0 1em;

        .errorMessage {
          color: red;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;
        }

        .autocomplete-dropdown-container {
          .suggestion-item,
          .suggestion-item--active {
            border-radius: 16px;
            @include box-shadow();
            padding: 10px 5px;
            margin: 0.25em 0;

            span {
              width: 100%;
              height: auto;
              @include font-size(16);
              padding: 0 1em;

              &:not(:last-of-type) {
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
  }
}
