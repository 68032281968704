@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.LandlordReference {
  @include full-box();
}

.LandlordReferencePlaces {
  .autocomplete-dropdown-container {
    .suggestion-item,
    .suggestion-item--active {
      border-radius: 3px;
      @include box-shadow();
      border-bottom: 1px solid #e0e0e0;
      padding: 10px 5px;
      margin: 0;
      span {
        width: 100%;
        height: auto;
        @include font-size(16);
        padding: 0 1em;

        &:not(:last-of-type) {
          margin-bottom: 5px;
        }
      }
    }
  }
}
