@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.Navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: $header-height;
  width: 100%;
  border-bottom: 1px solid $border-color;
  background: $white;
  z-index: 998;
  @include flex-column();

  nav {
    @include flex-row();
    height: 100%;
    @include md() {
      margin-top: 5px;
    }
  }

  .Navbar__notification-btn {
    @include circle(38px);
    background-image: url('../../assets/svg/bell.svg');
    background-repeat: no-repeat;
    background-size: 21px 24px;
    background-position: center;
    background-color: $white;

    margin-right: 30px;

    &:hover, &:focus {
      background-color: $border-color;
    }
  }

  .Navbar__user-name {
    @include font-size(18);
    @include font-heading();
    text-transform: lowercase;
    border-left: 1px solid $border-color;
    padding-left: 30px;
    padding-right: 20px;

    @include md() {
    white-space: nowrap;
    justify-content: flex-start;
    padding: 5px 20px;
    display: block;
    border: none;
    font-size: 1rem; 
    }
  }

  .Navbar__dropdown {
    position: relative;

    .Navbar__dropdown__btn {
      width: 36px;
      text-align: center;
      @include flex-row();
      background-color: none;
    height: 2rem;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    z-index: 0;
    display: none;
   
    padding-left: 30px;
    padding-right: 20px;

    @include md() {
      display: flex;
    }
      .burger {
        width: 2rem;
        height: 0.25rem;
        border-radius: 10px;
        background-color: $primary-color;
        transform-origin: 1px;
        transition: all 0.3s linear;
    }
    }

    .Navbar__dropdown__menu {
      width: auto;
      top: 0px;
      right: 0;
      border-radius: 8px;
      padding: 10px 0px;
      align-items: center;
      justify-content: space-around;
      display: flex;
      @include transition();
      
      @include md(){
        display: none;
        flex-direction: column;
        top: calc(100% + 10px);
        border: 2px solid $border-color;
        @include box-shadow;
        position: absolute;
        width: auto;
        justify-content: flex-start;
        align-items: center;
        right: 0;
        background-color: white;
      }

      &.Navbar__dropdown__menu--active {
        display: flex;
      }

.login-btn {
  @include md(){
    margin: 10px;
  }
}
      .logout-btn { 
        @include md(){
          margin-top: 5px;
        }
        margin-top: 0px;
        padding: 5px 20px;
      }

      .Navbar__dropdown__menu-item {
        padding: 5px 20px;
        display: block;
        flex-shrink: 0;
        white-space: wrap;
        @include font-size(18);
        line-height: 1.2;
        width: 100%;
        text-align: left;
        border-radius: 0px;
        font-family: $font-light;

        &:hover, &:focus {
          color: $primary-color;
        }

        @include md(){
          white-space: nowrap;
          justify-content: flex-start;  
          padding: 5px 20px;
          display: block;
        }
      }
    }
  }
}
