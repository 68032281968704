@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.EmploymentIncomeVerification {
  @include full-box();
  padding: 50px 50px; 
}

.application-preview-badge {
  border-radius: 16px!important;
  top: unset!important;
  left: unset!important;
  position: relative!important;
  margin-top: 0!important;
  margin-left: 0!important;
}