@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.ApplicationPreview {

  @include full-box();

  .ApplicationPreview__container {
    margin-top: 40px;
    @include space-y(40px);
  }

  .ApplicationPreview__coapplicants-container {
    border-radius: 16px;
    @include box-shadow();
    padding: 20px 20px 10px 20px;

    .ApplicationPreview__coapplicants {
      @include flex-row(flex-start);
      flex-wrap: wrap;

      .ApplicationPreview__coapplicant {
        margin-bottom: 10px;
        border-radius: 34px;
        padding: 0px 15px;
        border: 1px solid $border-color;
        line-height: 34px;

        &:not(:last-of-type){
          margin-right: 10px;
        }
      }
    }
  }
}