@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.Housing {
  .form-group {
  }

  @include full-box();
  padding: 50px 50px;
  .choose-input-group {
    width: auto;
    min-width: 80px;
    height: auto;
    line-height: 14px;
    border-radius: 40px;
    padding: 13px 20px;
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 700;
    font-family: "Montserrat Alternates", sans-serif;
    color: #000105;
    border: 2px solid #e5e5e5;
    background: #e5e5e5;
    transition: all 200ms ease-in-out;
    text-align: center;
    cursor: pointer;
    margin-bottom: 0px;

    .btn--back {
      margin-bottom: 65px;
    }

    &:hover {
      background-color: $primary-color-light;
      border-color: $primary-color-light;
    }
    &.active {
      background-color: $primary-color-light;
      border-color: $primary-color-light;
    }
  }
  .text-danger {
    color: #dc3545;
    font-size: 1rem;
  }

    .autocomplete-dropdown-container {
      .suggestion-item,
      .suggestion-item--active {
        border-radius: 3px;
        @include box-shadow();
        border-bottom: 1px solid #e0e0e0;
        padding: 10px 5px;
        margin: 0;
        span {
          width: 100%;
          height: auto;
          @include font-size(16);
          padding: 0 1em;

          &:not(:last-of-type) {
            margin-bottom: 5px;
          }
        }
      }
    }

}
