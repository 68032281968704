@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.multi-range-filter-container {

  height: auto !important;
  min-height: 40px;
  display: flex;
  width: 100%;
  align-items: center;
  margin: 15px 0 0 0;

  .slider {
    position: relative;
    width: 50%;
  }

  .slider__track,
  .slider__range {
    border-radius: 3px;
    height: 5px;
    position: absolute;
  }

  .slider__track {
    background-color: #F0F0F1;
    opacity: 1 !important;
    height: 15px;
    width: 100%;
    z-index: 1;
    border-radius: 8px;
  }

  .slider__range {
    background-color: $primary-color !important;
    height: 15px;
    width: 0;
    opacity: 1 !important;
    left: 1px;
    right: 1px;
    border-radius: 8px;
    z-index: 2;
  }

  .slider__values__container {
    position: absolute;
    display: flex;
    justify-content: start;
    align-items: center;
  }

  .slider__left-value,
  .slider__right-value {
    font-size: 18px;
    margin-bottom: 50px;
  }

  .slider__left-value {
    left: 6px;
    margin-right: 5px;
  }

  .slider__right-value {
    right: 11px;
  }

  input[type="range"]::-webkit-slider-thumb {
    background: $primary-color;
    cursor: pointer;
    border-radius: 100%;
    margin-top: 4px;
    @include transition();
  }

  input[type="range"]::-moz-range-thumb {
    border-radius: 100%;
    background-color: $primary-color;
    cursor: pointer;
    margin-top: 4px;
    @include transition();
  }

  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }

  .thumb {
    pointer-events: none;
    position: absolute;
    margin-top: 9px;
    height: 0;
    width: 45% !important;
    opacity: 1 !important;
    outline: none;
  }

  .thumb--zindex-3 {
    z-index: 3;
  }

  .thumb--zindex-4 {
    z-index: 4;
  }

  .thumb--zindex-5 {
    z-index: 5;
  }

  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: #8CC947FF !important;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    margin-top: 40px;
    pointer-events: all;
    position: relative;
  }

  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #8CC947FF !important;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    margin-top: 40px;
    pointer-events: all;
    position: relative;
  }
}
