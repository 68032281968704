.info__label {
  @include font-size(20);
  @include font-heading();
  margin-bottom: 10px;

  &.info__label--completed {
    color: $primary-color;
  }
  &.info__label--analyzing {
    color: $orange;
  }
  &.info__label--incomplete {
    color: $danger-color;
  }
}

.check-pass {
  @include flex-row(flex-start);
  @include font-heading();
  @include font-size(20);

  > * + * {
    margin-left: 10px;
  }
}

.application-preview-container {
  @include full-width();
  border-radius: 16px;
  border: 1px solid $border-color;
  padding: 20px 20px 10px 20px;
  position: relative;

  p {
    margin-bottom: 30px;
  }
}

.application-preview-badge {
  border-top-left-radius: 13px;
  border-bottom-right-radius: 16px;
  background: $black;
  color: $white;
  padding: 0px 20px;
  @include font-size(18);
  @include font-heading();
  @include weight-bold();
  display: inline-block;
  width: auto;
  height: 40px;
  position: relative;
  margin-top: -20px;
  margin-left: -20px;
  margin-bottom: 30px;
  top: -8px;

  .application-preview-badge__inner {
    width: auto;
    height: 100%;
    display: inline-flex;
    align-items: center;
    @include space-x(8px);
  }
}

.info-title-container {
  @include full-width();
  @include flex-row(space-between);
  @include space-x(10px);
  margin-bottom: 30px;

  //@include sm() {
  //  flex-direction: column-reverse;
  //  align-items: flex-start;
  //  @include space-x(0px);
  //  > *:not(:first-of-type){
  //    margin-bottom: 20px;
  //  }
  //
  //  .info-title-controls {
  //    align-self: flex-end;
  //  }
  //}

  .info-title h2 {
    margin-bottom: 0px;
  }

  .info-title__title-container {
    @include flex-row(flex-start);
    @include space-x(20px);
  }

  .info-title-controls {
    @include flex-row();
    @include space-x(20px);
  }
}
