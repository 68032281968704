@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.GuarantorLink {
  @include full-box();
}

.GuarantorLinkThankyou {
  @include full-box();
  @include flex-column(center, flex-start);
}