.order-summary {
  border-radius: 16px;
  border: 1px solid $border-color;
  margin-bottom: 40px;

  .order-summary__details {
    padding: 20px 30px 40px 20px;
  }

  .order-summary__total {
    padding: 20px 30px;
    background: rgba($secondary-color, 0.1);
    border-top: 2px solid $border-color;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    h2 {
      margin-bottom: 0;
      margin-top: 0px;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }

  .order-summary__order-item {
    @include flex-row(space-between);
    margin-bottom: 15px;

    span:first-of-type {
      @include font-heading();
      @include weight-normal();
      @include font-size(16);
      color: $black;

      line-height: 1.3;
    }
    span:last-of-type {
      @include font-text();
      @include weight-thin();
      @include font-size(16);
      color: $black;

      line-height: 1.3;
      margin-left: 5px;
    }
    &.order-summary__order-item--large {
      margin-bottom: 25px;

      span:first-of-type {
        @include font-size(20);
        @include weight-normal();
      }
    }
  }
}

