@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.EmploymentInfo {
  @include full-box();
  padding: 50px 50px;

  .EmploymentInfo__box {
    border: 1px solid $border-color;
    padding: 15px;
    margin-bottom: 60px;
    border-radius: 16px;

    h4 {
      margin-bottom: 20px;
    }
  }

  .choose-input-group {
    width: auto;
    min-width: 80px;
    height: auto;
    line-height: 14px;
    border-radius: 40px;
    padding: 13px 20px;
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 700;
    font-family: "Montserrat Alternates", sans-serif;
    color: #000105;
    border: 2px solid #e5e5e5;
    background: #e5e5e5;
    transition: all 200ms ease-in-out;
    text-align: center;
    cursor: pointer;
    margin-bottom: 0px;

    &:hover {
      background-color: $primary-color-light;
      border-color: $primary-color-light;
    }
    &.active {
      background-color: $primary-color-light;
      border-color: $primary-color-light;
    }
  }

  .current-employment {
    .choose-input-group {
      @include sm(){
        margin-top: 10px;
        margin-left: 0px;
        min-width: 180px;
      }
    }
  }
}
.EmploymentInfo_error-message {
  color: $danger-color;
  font-size: 20px;
}
