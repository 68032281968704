@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.CreditReport {
  @include full-box();
  padding: 50px 50px; 
}

.text-danger {
  color: #dc3545;
  font-size: 0.9rem;
  margin-top: -1.3rem;
}