@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.guarantor-info__signature {
  padding: 20px;
  border: 1px solid $border-color;
  border-radius: 16px;

  img {
    height: 60px;
    width: auto;
    object-fit: contain;
  }
}