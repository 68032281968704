@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.LandlordReferences {
  @include full-box();
  padding: 50px 50px;
  .container {
    .llrbtn-add-more {
      margin-top: 1em;
    }
  }
  .LandlordReferences__references {
    @include space-y(30px);
  }

  .LandlordReferences__reference {
    border: 1px solid $border-color;
    padding: 20px;
    border-radius: 16px;
    position: relative;

    .btn {
      min-width: unset !important;
    }

    .btn-group {
      .btn:not(:last-of-type) {
        margin-right: 6px;
      }
    }
  }
}
