@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.Login {
  @include full-page();
  padding-top: $header-height;

  .CheckboxInput input:checked {
    background-color: green;
  }

.link-2 {
  border-bottom: 2px solid; 
  font-size: 1rem;
}
  .Login__form-container {
    @include full-height();
    @include flex-column(center, flex-start);
    padding: 50px 100px;
  }

  .login-links {
    margin-top: 40px;
  }

  .Login__img {
    width: 100%;
    height: 500px;
    padding: 0px 0px;
    object-fit: cover;
    object-position: center;
    margin: 20px 0px;
    @include sm(){
      height: 160px;
    }
  }
  
  .Login__error {
    color: red;
    padding: 1em 0;
  }
}
