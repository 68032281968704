@import "src/assets/sass/common/variables.scss";
@import "src/assets/sass/common/mixins.scss";

.FilterModal {

  label {
    @include font-heading();
  }

  .large--accent {
    min-width: 200px;
  }

  .choose-input-group, label {
    background-color: #FFF !important;
  }

  .choose-input-group .choose-input:checked + label {
    background-color: #e7e7e7 !important;
    border: 2px solid  #e0e0e0 !important;
  }

  .choose-input-group .choose-input:checked + label:hover {
    background-color: #e7e7e7 !important;
    border: 2px solid  #e0e0e0 !important;
  }
  .choose-input-group label:hover {
    border: 2px solid  #e0e0e0 !important;
  }
}

.premiumFeaturesContainer {
  width: 100%;
  position: relative;
  //height: auto;
  margin: 0 -25px 15px -25px;
  padding: 25px 25px 0 25px;
  background: rgba(224, 224, 224, 0.1);
  border: 1px solid #E0E0E0;
  border-radius: 16px;

  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px) {
    width: 65%;
    min-width: unset;
  }

    .hasZeroValue  {
  //  Grey out all the elements and their children contained in this class and make it so the user can't interact with any of them
    user-select: none;
    pointer-events: none !important;
    opacity: 0.4;

    :hover {
      user-select: none;
      pointer-events: none !important;
      cursor: default !important;
    }
  }

}
